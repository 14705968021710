import React from 'react'
import { OrderTimingType } from '../../dtos/companyDTO'
import { CompanyRestaurantDTO } from '../../dtos/companyRestaurantDTO'
import { RestaurantType } from '../../dtos/restaurantDTO'

const companyRestaurants: CompanyRestaurantDTO[] = [
    {
        id: '0',
        companyId: "",
        company: {
            address: {
                cityName: "Alexandrie",
                country: "Egypte",
                id: "1",
                number: "23",
                streetName: "rue de petra",
                zipCode: "1640"
            },
            addressId: "1",
            deliveryComments: "In front of the door",
            id: "0",
            name: "Proximus",
            vatNumber: "19457594939",
            logo: '',
            mobileLogo: '',
            orderTimingType: OrderTimingType.Noon,
            code: ''
        },
        restaurantId: "1",
        restaurant: {
            id: '0',
            name: "Pizzeria",
            pictureUrl: 'https://food-images.files.bbci.co.uk/food/recipes/simple_fish_dish_98008_16x9.jpg',
            hoverPictureUrl: 'https://food-images.files.bbci.co.uk/food/recipes/simple_fish_dish_98008_16x9.jpg',
            restaurantType: RestaurantType.BOTH
        },
        indexPrice: 0,
        isAssigned: true
    }
]



async function get(companyId?: string): Promise<any> {

    return companyRestaurants
}

async function put(companyId: string, companyRestaurant: CompanyRestaurantDTO) {
    const data = companyRestaurants.find(item => item.id === companyRestaurant.id)

    if (data) {
        data.indexPrice = companyRestaurant.indexPrice
        data.isAssigned = companyRestaurant.isAssigned
    }

    return data
}


export default { get, put }