import React, { useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Header from "../../../../../../../../components/header";
import SaveButton from "../../../../../../../../components/header/components/save-button";
import { SAVE_BUTTON_TYPE } from "../../../../../../../../components/header/components/save-button/props";
import ProductService from "../../../../../../../../services/ProductService";
import MenuContainer from "../../../../../../components/menu-container";
import PageContainer from "../../../../../../components/page-container";
import { customStyles } from "../../../../../../components/popup/customStyle";
import UploadPicture from "../../../../components/upload-picture";
import ProductInformation from "./components/information";
import PromoDescription from "./components/priority";
import { initialProduct, reducer } from "./reducer";
import Modal from "react-modal";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/delete-icon.svg";
import "./style.css";
import Popup from "../../../../../../components/popup";
import PopupButton from "../../../../../../../../components/popup-button";
import Form from "../../../../../../components/form";
import { usePopupContext } from "../../../../../../components/popup-context/context";
import { EnumPopupIndex } from "../../../../../../components/popup-context/props";
import BusyIndicator from "../../../../../../../../components/busy-indicator";
import SideDishes from "./components/side-dishes";
import { Priority, ProductResDTO, ProductTagType, TagDTO } from "../../../../../../../../dtos/go-api";
import { useCreateRestaurantProduct, useGetProductById, useGetSubProductsByProductId, useUpdateProductById } from "../../../../../../../../services/go-api/product/product";
import VisibleTags from "./components/tags/visible";
import { useGetProductTagsByProductId } from "../../../../../../../../services/go-api/product-tags/product-tags";
import InvisibleTags from "./components/tags/invisible";
import Tag from "../../../../../../../../components/tag";

const DishDetail = () => {
	// Attributes
	const history = useHistory();
	const { productId, restaurantId }: any = useParams();

	const query = useGetProductById(restaurantId, productId, {
		query: {
			enabled: !!productId && !!restaurantId,
			onSuccess(data) {
				dispatch({ type: "serverChanged", target: "self", payload: data });
			},
			refetchOnWindowFocus: false,
		},
	});
	useGetProductTagsByProductId(
		productId,
		{
			status: ProductTagType.VISIBLE,
		},
		{
			query: {
				enabled: !!productId,
				onSuccess(productTagPaging) {
					dispatch({
						type: "productChanged",
						target: "visibleTags",
						payload: productTagPaging.data,
					});
				},
			},
		}
	);
	useGetProductTagsByProductId(
		productId,
		{
			status: ProductTagType.INVISIBLE,
		},
		{
			query: {
				enabled: !!productId,
				onSuccess(productTagPaging) {
					dispatch({
						type: "productChanged",
						target: "invisibleTags",
						payload: productTagPaging.data,
					});
				},
			},
		}
	);

	const [product, dispatch] = useReducer(reducer, initialProduct);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { showPopup } = usePopupContext();
	const { data: subProductsResult } = useGetSubProductsByProductId(
		product?.id ?? "",
		{},
		{
			query: {
				enabled: !!product?.id,
				onSuccess(res) {
					setSelectedSubProducts(res?.data ?? []);
				},
			},
		}
	);
	const subProducts = subProductsResult?.data ?? [];
	const [selectedSubProducts, setSelectedSubProducts] = useState<ProductResDTO[]>(subProducts ?? []);

	// Queries
	const { mutateAsync: createProduct } = useCreateRestaurantProduct();
	const { mutateAsync: updateProduct } = useUpdateProductById();

	function isUpdated() {
		if (query.data && productId) {
			for (const attribute in product) {
				if (!Object.is((query.data as any)[attribute], product[attribute])) {
					return true;
				}
			}
		}
		return false;
	}

	async function handleSave() {
		let hasPriority = product.priority === Priority.HIGH || product.priority === Priority.MEDIUM ? true : false;

		for (const attribute in product) {
			if (attribute === "name" || attribute === "pictureUrl" || attribute === "unitPrice" || attribute === "description" || attribute === "pictureUrl") {
				if (!product[attribute] || product[attribute] === 0) {
					showPopup(EnumPopupIndex.MANDATORY);
					return;
				}

				if (hasPriority) {
					if (!product.promoDescription) {
						showPopup(EnumPopupIndex.MANDATORY);
						return;
					}
				}
			}
		}

		const { invisibleTags, visibleTags, ...rest } = product;

		if (productId) {
			updateProduct(
				{
					productId: productId,
					restaurantId: restaurantId,
					data: {
						...rest,
						unitPrice: Number(rest.unitPrice),
						subProductIds: selectedSubProducts?.map((p: any) => p.id ?? ""),
						productTags: [...invisibleTags, ...visibleTags],
					},
				},
				{
					onSuccess: () => {
						history.goBack();
					},
				}
			);
		} else {
			product.restaurantId = restaurantId;
			createProduct(
				{
					restaurantId: product.restaurantId,
					data: {
						...rest,
						unitPrice: Number(rest.unitPrice),
						subProductIds: selectedSubProducts?.map((p: any) => p.id ?? ""),
						tags: [...visibleTags.map((tag: TagDTO) => ({ ...tag, tagId: tag.id })), ...invisibleTags.map((tag: TagDTO) => ({ ...tag, tagId: tag.id }))],
					},
				},
				{
					onSuccess: () => {
						history.goBack();
					},
				}
			);
		}
	}

	function handleChangePictureUrl(pictureUrl: string) {
		dispatch({
			type: "productChanged",
			target: "pictureUrl",
			payload: pictureUrl,
		});
	}

	function handleChangeRecomandationPictureUrl(recommendationPictureUrl: string) {
		dispatch({
			type: "productChanged",
			target: "recommendationPictureUrl",
			payload: recommendationPictureUrl,
		});
	}

	function handleChangeMobileRecomandationPictureUrl(mobileRecommendationPictureUrl: string) {
		dispatch({
			type: "productChanged",
			target: "mobileRecommendationPictureUrl",
			payload: mobileRecommendationPictureUrl,
		});
	}

	async function handleDelete() {
		await ProductService.deleteProduct(restaurantId, productId);
		history.push(`/dashboard/restaurants/${restaurantId}/dishes`);
	}

	return (
		<PageContainer>
			<Header isUpdated={isUpdated()} title={productId ? product.name : "Add new product"} hasBackButton={true} saveButton={<SaveButton handleClick={handleSave} type={productId ? SAVE_BUTTON_TYPE.SAVE : SAVE_BUTTON_TYPE.SAVE_AND_ADD} handleDelete={() => setIsModalOpen(true)} />} />
			<MenuContainer>
				<BusyIndicator query={query}>
					<Form>
						<div className="product-details-form-container">
							<div className="product-details-form-column">
								<div className="product-details-form-information-container">
									<ProductInformation product={product} dispatch={dispatch} />
									{(product.priority === Priority.HIGH || product.priority === Priority.MEDIUM) && <PromoDescription product={product} dispatch={dispatch} />}
								</div>
								<SideDishes selectedSubProducts={selectedSubProducts} setSelectedSubProducts={setSelectedSubProducts} />
							</div>
							<div className="product-details-form-column">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										margin: "0px 20px 0px 10px",
									}}
								>
									<UploadPicture uploadContainerSize={85} pictureUrl={product.pictureUrl} handleChangePictureUrl={handleChangePictureUrl} />
									{(product.priority === Priority.HIGH || product.priority === Priority.MEDIUM) && (
										<div>
											<UploadPicture title="UploadRecommendationImage" uploadContainerSize={85} pictureUrl={product.recommendationPictureUrl} handleChangePictureUrl={handleChangeRecomandationPictureUrl} />

											<UploadPicture title="UploadRecommendationImageMobile" uploadContainerSize={85} pictureUrl={product.mobileRecommendationPictureUrl} handleChangePictureUrl={handleChangeMobileRecomandationPictureUrl} />
										</div>
									)}
									<VisibleTags product={product} dispatch={dispatch} />
									<InvisibleTags product={product} dispatch={dispatch} />
								</div>
							</div>
						</div>
					</Form>
				</BusyIndicator>
			</MenuContainer>
			<Modal style={customStyles} isOpen={isModalOpen}>
				<Popup title="Are you sure you want to delete this dish?" icon={<DeleteIcon style={{ width: 66, height: 69 }} />} buttons={[<PopupButton backgroundColor="grey" handleClick={() => setIsModalOpen(false)} title="Cancel" />, <PopupButton backgroundColor="yellow" handleClick={handleDelete} title="Yes, delete" />]} />
			</Modal>
		</PageContainer>
	);
};

export default DishDetail;
