import React from "react";
import { OrderTicketHeaderProps } from "./props";
import "./style.css";

export default function OrderTicketHeader({ order }: OrderTicketHeaderProps) {
	return (
		<div className="ticket-header-container">
			<div className="ticket-header-company-info">
				<div className="ticket-header-company-name">
					{order?.companyName}
				</div>
				<div className="ticket-header-company-address">
					<span>{`${order?.companyAddress?.streetName} ${order?.companyAddress?.number}, ${order?.companyAddress?.zipCode}`}</span>
					<span>{order?.companyAddress?.cityName}</span>
				</div>
			</div>
			<div className="ticket-header-user-info">
				<span className="ticket-header-user-name">{`${order?.userFirstName} ${order?.userLastName}`}</span>
				<div className="ticket-header-user-details">
					<span>{order?.userEmail}</span>
					<span>{order?.userPhoneNumber}</span>
				</div>
			</div>
		</div>
	);
}
