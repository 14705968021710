import React from 'react'
import { RestaurantDTO, RestaurantType } from '../../dtos/restaurantDTO'

const restaurants: RestaurantDTO[] = [
    { id: '0', name: "Pizzeria", pictureUrl: '', restaurantType: RestaurantType.BOTH },
    { id: '1', name: "Brasserie", pictureUrl: '', restaurantType: RestaurantType.B2C },
    { id: '2', name: "Ecailler", pictureUrl: '', restaurantType: RestaurantType.B2C },
    { id: '3', name: "Poissonnerie", pictureUrl: '', restaurantType: RestaurantType.B2C },
    { id: '4', name: "Boucherie", pictureUrl: '', restaurantType: RestaurantType.B2C },
    { id: '5', name: "Boulangerie", pictureUrl: '', restaurantType: RestaurantType.B2C },
]



async function get(restaurantId?: string): Promise<any> {
    if (restaurantId && restaurants.find((restaurant) => restaurant.id === restaurantId)) {

        return restaurants.find((restaurant) => restaurant.id === restaurantId)
    }

    return restaurants
}

async function put(restaurant: RestaurantDTO) {
    const data = restaurants.find(item => item.id === restaurant.id)

    if (data) {
        data.name = restaurant.name
        data.pictureUrl = restaurant.pictureUrl
        data.restaurantType = restaurant.restaurantType
    }

    return data
}

async function post(restaurant: RestaurantDTO) {
    restaurant.id = restaurants.length.toString()
    return restaurants.push(restaurant)
}

async function deleteRestaurant(restaurantId: string) {
    const index = restaurants.findIndex(restaurant => restaurant.id === restaurantId)
    restaurants.splice(index, 1)
    return restaurants
}


export default { get, put, post, deleteRestaurant }