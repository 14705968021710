import React, { useEffect, useMemo, useState } from "react";
import PageContainer from "../../../../components/page-container";
import AppVersioningListHeader from "./components/header";
import { Row, useTable } from "react-table";
import AppVersioningFilters from "./components/filters";
import "./style.css";
import Lottie from "react-lottie";
import animationData from "./assets/loading.json";
import {
  useGetAllConfigurations,
  useUpdateConfigurationVersionStateByConfigurationId,
} from "../../../../../../services/go-api/configuration/configuration";
import { DeviceType } from "../../../../../../dtos/go-api";
import Toggle from "../../../../components/toggle";

const MAX_SIZE_PER_PAGE = 10;

const AppVersioningList = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [search, setSearch] = useState<string>("");
  const [currentTotalPages, setCurrentTotalPages] = useState<number>(0);

  const {
    data: versions,
    refetch,
    isSuccess,
    isLoading,
  } = useGetAllConfigurations(
    {
      size: MAX_SIZE_PER_PAGE,
      page: currentPage,
      q: search,
    },
    { query: { refetchOnWindowFocus: false } }
  );

  const { mutateAsync: updateConfiguration } =
    useUpdateConfigurationVersionStateByConfigurationId();

  const columns = useMemo(() => {
    async function handleToggle(check: boolean, id: string) {
      await updateConfiguration(
        {
          configurationId: id,
          data: {
            isEnabled: check,
          },
        },
        {
          onSuccess: async () => {
            await refetch();
          },
        }
      );
    }
    return [
      {
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: "#1E1F20" }}>{value}</span>
        ),
      },
      {
        Header: "Version",
        accessor: "version",
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: "#1E1F20" }}>{value}</span>
        ),
      },
      {
        Header: "Operating System",
        accessor: "os",
        Cell: ({ value }: { value: DeviceType }) => (
          <span style={{ color: "#1E1F20" }}>{value}</span>
        ),
      },
      {
        Header: "Force Update",
        accessor: "disabled",
        Cell: ({ row }: { row: Row }) => {
          return (
            <Toggle
              handleClick={(checked) => handleToggle(!checked, row.values.id)}
              defaultValue={row.values.disabled}
            />
          );
        },
      },
    ];
  }, [refetch, updateConfiguration]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: isSuccess ? versions.data ?? [] : [],
    });

  // Effects
  useEffect(() => {
    if (versions?.nbPages !== undefined) {
      setCurrentTotalPages(versions?.nbPages);
    }
  }, [versions?.nbPages]);
  // Render
  return (
    <PageContainer>
      <AppVersioningListHeader />
      <AppVersioningFilters
        page={currentPage}
        totalPages={currentTotalPages}
        setPage={setCurrentPage}
        search={search}
        setSearch={setSearch}
      />
      <div className='versioning-table-container'>
        {isLoading ? (
          <tr className='versioning-table-placeholder-container'>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={300}
              width={300}
            />
          </tr>
        ) : (
          <table {...getTableProps()} className='versioning-table'>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* Apply the table body props */}
            <tbody style={{ textAlign: "center" }} {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        )}
      </div>
    </PageContainer>
  );
};

export default AppVersioningList;
