import React from 'react';
import { PrintableOrderItemsByRestaurantProps } from './props';
import './styles.css';
import capitalize from 'lodash/capitalize';

const PrintableOrderItemsByRestaurant = ({
	items,
	restaurantName,
}: PrintableOrderItemsByRestaurantProps) => {
	return (
		<div className='order-ticket-items-by-restaurant-container'>
			<div className='order-ticket-items-by-restaurant-title'>
				{capitalize(restaurantName.toLowerCase())}
			</div>
			<div className='order-ticket-items-by-restaurant-bar' />
			<div className='order-ticket-items-by-restaurant-dishes'>
				{items.map((item) => {
					return (
						<div className='order-ticket-dish-row'>
							<div className='order-ticket-dish-row-details'>
								<div className='order-ticket-dish-row-name'>{item?.name}</div>
								{item.quantity && item?.quantity > 1 ? (
									<div className='order-ticket-dish-row-quantity-multiple'>
										<div>{item.quantity}</div>
									</div>
								) : (
									<div className='order-ticket-dish-row-quantity'>
										{item.quantity}
									</div>
								)}
							</div>
							<div className='order-ticket-dish-row-amount'>{`${item?.totalAmount?.toFixed(
								2
							)} €`}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PrintableOrderItemsByRestaurant;
