import React, { useEffect, useMemo, useState } from 'react';
import PageContainer from '../../../../components/page-container';
import OrdersListHeader from './components/header';
import useQueryString from '../../../../../../hooks/useQueryString';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import { TimeScale } from './components/header/props';
import { utcToZonedTime, format } from 'date-fns-tz';
import PrintableOrder from './components/printable-order';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg';
import { useGetOrders } from '../../../../../../services/go-api/order/order';
import RestaurantsIndicator from './components/restaurants-indicator';
import OrdersFilters from './components/filters';
import {
  FoodizPaymentMethod,
  OrderDTO,
  OrderPromoCodeDTO,
} from '../../../../../../dtos/go-api';
import './style.css';
import { formatISO } from 'date-fns';
import Lottie from 'react-lottie';
import animationData from './assets/loading.json';
import Modal from 'react-modal';
import Popup from '../../../../components/popup';
import { ReactComponent as PrinterIcon } from './assets/print-big.svg';
import PopupButton from '../../../../../../components/popup-button';
import { EnumFilterTypes, filterTypes, OrderContent } from './types';
import { parse } from 'date-fns';
import Checkbox from './components/checkbox';
import SelectionBanner from './components/selection-banner';
import NotificationByOrdersDialog from './components/notification-dialog';
import OrderLogService from '../../../../../../services/OrderLogService';
import { OrderLogType as NewOrderLogType } from '../../../../../../dtos/orderLogDTO';
import BusyIndicator from '../../../../../../components/busy-indicator';
const MAX_SIZE_PER_PAGE = 30;
const timeZone = 'Europe/Berlin';

const OrdersList = () => {
  // Attributes
  const history = useHistory();
  const orderType = useQueryString().get(EnumFilterTypes.TIME_SCALE);
  const searchParam = useQueryString().get(EnumFilterTypes.SEARCH);
  const companiesParam = useQueryString().get(EnumFilterTypes.COMPANIES);
  const restaurantsParam = useQueryString().get(EnumFilterTypes.RESTAURANTS);
  const productsParam = useQueryString().get(EnumFilterTypes.PRODUCTS);
  const paymentTypesParam = useQueryString().get(EnumFilterTypes.PAYMENT_TYPES);
  const minDateParam = useQueryString().get(EnumFilterTypes.MIN_DATE);
  const maxDateParam = useQueryString().get(EnumFilterTypes.MAX_DATE);
  const pageNumberParam = useQueryString().get(EnumFilterTypes.PAGE_NUMBER);

  const timeScale = orderType as TimeScale;

  //useStates
  const [startDate, setStartDate] = useState<Date | null>(
    minDateParam ? parse(minDateParam, 'dd/MM/yyyy', new Date()) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    maxDateParam ? parse(maxDateParam, 'dd/MM/yyyy', new Date()) : null
  );
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumberParam ? parseFloat(pageNumberParam) : 0
  );
  const [search, setSearch] = useState<string>(searchParam ? searchParam : '');
  const [currentTotalPages, setCurrentTotalPages] = useState<number>(0);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>(
    !!companiesParam ? companiesParam.split(',') : []
  );
  const [selectedRestaurants, setSelectedRestaurants] = useState<string[]>(
    !!restaurantsParam ? restaurantsParam.split(',') : []
  );
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    !!productsParam ? productsParam.split(',') : []
  );
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<
    FoodizPaymentMethod[]
  >(
    !!paymentTypesParam
      ? (paymentTypesParam.split(',') as FoodizPaymentMethod[])
      : []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] =
    useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderDTO>();

  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);

  const [selectedContentOrders, setSelectedContentOrders] = useState<
    OrderContent[]
  >([]);

  const [isEveryOrderSelected, setisEveryOrderSelected] =
    useState<boolean>(false);

  const [minDate, maxDate] = useMemo(() => {
    const now = new Date();
    switch (timeScale) {
      case TimeScale.Hour: {
        const min = new Date(now);
        min.setHours(now.getHours() - 1);
        return [min, now];
      }
      case TimeScale.Day: {
        const min = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 1,
          14,
          0,
          0
        );
        const max = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        );
        return [min, max];
      }
      case TimeScale.Week: {
        const min = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - now.getDay() + 1,
          0,
          0,
          0
        );
        const max = new Date(
          now.getFullYear(),
          now.getMonth(),
          min.getDate() + 6,
          23,
          59,
          59
        );
        return [min, max];
      }
      case TimeScale.Month: {
        const min = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
        const max = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          0,
          23,
          59,
          59
        );
        return [min, max];
      }
      case TimeScale.Pick: {
        // Check only one date is selected
        let maxDate;
        if (!endDate) {
          // Get end of the start date
          if (startDate) {
            maxDate = new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              23,
              59,
              59
            );
          }
        } else {
          maxDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            23,
            59,
            59
          );
        }

        return [startDate, maxDate];
      }
      default: {
        return [undefined, undefined];
      }
    }
  }, [timeScale, startDate, endDate]);

  //queries
  const query = useGetOrders(
    {
      size: MAX_SIZE_PER_PAGE,
      page: currentPage,
      companyIds: selectedCompanies,
      restaurantIds: selectedRestaurants,
      paymentTypes: selectedPaymentTypes,
      productIds: selectedProducts,
      q: search,
      minDate: minDate ? formatISO(minDate) : undefined,
      maxDate: maxDate ? formatISO(maxDate) : undefined,
    },
    { query: { refetchInterval: 1000 } }
  );

  const { data: orders, isSuccess, isLoading } = query;

  const numberOfRows = useMemo((): number => {
    //Setting the numberOfRows to orders.data.length or 0
    let numberOfRowsTemp = orders && orders.data ? orders.data.length : 0;

    if (numberOfRowsTemp === 0) {
      setisEveryOrderSelected(false);
    }

    return numberOfRowsTemp;
  }, [orders]);

  // Joined filters arrays of string into flat string
  const filtersParams = useMemo((): string => {
    // Flat list
    let params = '';

    // List of filters
    const filterNames = Object.values(filterTypes);

    for (let i = 0; i < filterNames?.length; i++) {
      // Name of new query param
      const name = filterNames[i];

      // Values for query param
      let values: string[] | string | Date | undefined | null = [];

      switch (name) {
        case EnumFilterTypes.COMPANIES:
          values = selectedCompanies.toString();
          break;
        case EnumFilterTypes.RESTAURANTS:
          values = selectedRestaurants.toString();
          break;
        case EnumFilterTypes.PRODUCTS:
          values = selectedProducts.toString();
          break;
        case EnumFilterTypes.PAYMENT_TYPES:
          values = selectedPaymentTypes.toString();
          break;
        case EnumFilterTypes.SEARCH:
          values = search;
          break;
        case EnumFilterTypes.MIN_DATE:
          values = minDate ? minDate?.toLocaleDateString() : '';
          break;
        case EnumFilterTypes.MAX_DATE:
          values = maxDate ? maxDate?.toLocaleDateString() : '';
          break;
        case EnumFilterTypes.TIME_SCALE:
          values = orderType;
          break;
        case EnumFilterTypes.PAGE_NUMBER:
          values = currentPage.toString();
          break;
        default:
          break;
      }

      // Add esperluet when adding new query param
      const esperluet = i !== 0 ? '&' : '';

      // Join filters into flat string separated by commas
      params += `${esperluet}${name}=${values}`;
    }

    return params;
  }, [
    selectedCompanies,
    selectedRestaurants,
    selectedProducts,
    selectedPaymentTypes,
    minDate,
    maxDate,
    search,
    orderType,
    currentPage,
  ]);

  const columns = useMemo(() => {
    return [
      {
        Header: (
          <Checkbox
            name={'check_all'}
            value={isEveryOrderSelected && numberOfRows > 0}
            onChange={(checked) => {
              if (!checked && numberOfRows > 0) {
                setisEveryOrderSelected(true);
              } else if (checked) {
                setisEveryOrderSelected(false);
              }

              //if setting to true, empty selectedOrders
              if (checked) {
                setSelectedOrders([]);
              }
            }}
          />
        ),
        accessor: 'id',
        Cell: ({ cell }: any) => {
          const orderId = cell.row.values.id;
          return (
            <Checkbox
              name={orderId}
              value={selectedOrders.includes(orderId) || isEveryOrderSelected}
              onChange={(checked) => {
                if (checked && isEveryOrderSelected) {
                  setisEveryOrderSelected(false);
                }

                setSelectedOrders(
                  isEveryOrderSelected
                    ? //if isEveryOrderSelected is true, set selectedOrders to contain only the one selected
                      [orderId]
                    : // else
                    checked
                    ? //if checked true -> false remove current from selection
                      selectedOrders.filter((element) => element !== orderId)
                    : //if checked false -> true add current to selection
                      [...selectedOrders, orderId]
                );

                setSelectedContentOrders(
                  isEveryOrderSelected
                    ? //if isEveryOrderSelected is true, set selectedOrders to contain only the one selected
                      [
                        {
                          id: orderId,
                          email: cell.row.values.userEmail,
                          company: cell.row.values.companyName,
                        },
                      ]
                    : // else
                    checked
                    ? //if checked true -> false remove current from selection
                      selectedContentOrders.filter(
                        (element) => element.id !== orderId
                      )
                    : //if checked false -> true add current to selection
                      [
                        ...selectedContentOrders,
                        {
                          id: orderId,
                          email: cell.row.values.userEmail,
                          company: cell.row.values.companyName,
                        },
                      ]
                );
              }}
            />
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'userEmail', // accessor is the "key" in the data
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: '#1E1F20' }}>{value}</span>
        ),
      },
      {
        Header: 'Company',
        accessor: 'companyName',
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: '#1E1F20' }}>{value}</span>
        ),
      },
      {
        Header: 'Restaurant',
        accessor: 'restaurantNames',
        Cell: ({ value }: { value: string[] }) => (
          <RestaurantsIndicator restaurants={value} />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: { color: '#7F85A2' },
        Cell: ({ value }: { value: NewOrderLogType }) => {
          return (
            <span
              style={{
                color: value === NewOrderLogType.Printed ? '#0ead69' : '#000',
                fontWeight: 'bold',
              }}
            >
              {value === NewOrderLogType.Unknown ? '' : value}
            </span>
          );
        },
      },
      {
        Header: 'Time / Date',
        accessor: 'modifiedAt',
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: '#7F85A2' }}>
            {value &&
              format(
                utcToZonedTime(new Date(value), timeZone),
                'HH:mm - dd/MM/yyyy'
              )}
          </span>
        ),
      },
      {
        Header: 'Payment method',
        accessor: 'paymentMethod',
        style: { color: '#7F85A2' },
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: '#7F85A2' }}>{value}</span>
        ),
      },
      {
        Header: 'Price',
        accessor: 'price',
        style: { color: '#7F85A2' },
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: '#7F85A2' }}>{`${value.toFixed(2)}€`}</span>
        ),
      },
      {
        Header: 'Promo code',
        accessor: 'promoCode',
        style: { color: '#7F85A2' },
        Cell: ({ value }: { value: OrderPromoCodeDTO }) => (
          <span style={{ color: '#7F85A2' }}>
            {value
              ? `${value.name?.toUpperCase()} - ${value.amount} ${
                  value.type === 'PERCENTAGE' ? '%' : '€'
                }`
              : '/'}
          </span>
        ),
      },
      {
        Header: '',
        accessor: 'col1',
        Cell: ({ cell }: any) => {
          return (
            <PrintableOrder
              order={cell.row.original}
              setIsModalOpen={setIsModalOpen}
              setSelectedOrder={setSelectedOrder}
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'col2',
        Cell: ({ cell }: any) => (
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() =>
              history.push(
                `/dashboard/orders/${cell?.row?.original.id}?${filtersParams}`
              )
            }
          >
            <ArrowRight />
          </div>
        ),
      },
    ];
  }, [
    filtersParams,
    history,
    isEveryOrderSelected,
    numberOfRows,
    selectedContentOrders,
    selectedOrders,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: isSuccess ? orders.data ?? [] : [],
    });

  // Effects
  useEffect(() => {
    if (orders?.nbPages !== undefined) {
      setCurrentTotalPages(orders?.nbPages);
    }
  }, [orders?.nbPages]);

  //Functions
  async function handleOrderLogUpdate() {
    if (selectedOrder) {
      //create orderLog

      await OrderLogService.createOrderLog({
        orderId: selectedOrder?.id ?? '',
        orderLogType: 0,
      });

      //close modal
      setIsModalOpen(false);
      setSelectedOrder(undefined);
    }
  }

  // Render
  return (
    <PageContainer>
      <OrdersListHeader
        totalOrders={orders?.totalAmount ?? 0}
        timeScale={timeScale}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <OrdersFilters
        page={currentPage}
        totalPages={currentTotalPages}
        setPage={setCurrentPage}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompanies}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        selectedRestaurants={selectedRestaurants}
        setSelectedRestaurants={setSelectedRestaurants}
        selectedPaymentTypes={selectedPaymentTypes}
        setSelectedPaymentTypes={setSelectedPaymentTypes}
        search={search}
        setSearch={setSearch}
        minDate={minDate}
        maxDate={maxDate}
      />
      <div className='orders-table-container'>
        {isLoading ? (
          <tr className='orders-table-placeholder-container'>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={300}
              width={300}
            />
          </tr>
        ) : (
          <BusyIndicator queries={[query]}>
            <table {...getTableProps()} className='orders-table'>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {/* Apply the table body props */}
              <tbody style={{ textAlign: 'center' }} {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  rows.map((row) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr
                        style={{
                          backgroundColor:
                            row.values.status === NewOrderLogType.Printed
                              ? '#ffeab894'
                              : '',
                        }}
                        {...row.getRowProps()}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </BusyIndicator>
        )}
      </div>
      <Modal
        ariaHideApp={false}
        style={customStyles}
        isOpen={isModalOpen && !!selectedOrder}
      >
        <Popup
          title='didOrderPrint'
          icon={<PrinterIcon style={{ width: 66, height: 69 }} />}
          buttons={[
            <PopupButton
              backgroundColor='grey'
              handleClick={(event: any) => {
                setIsModalOpen(false);
                setSelectedOrder(undefined);
                event.stopPropagation();
              }}
              title='NO'
            />,
            <PopupButton
              backgroundColor='yellow'
              handleClick={(e: any) => {
                handleOrderLogUpdate();
                e.stopPropagation();
              }}
              title='YES'
            />,
          ]}
        />
      </Modal>
      <SelectionBanner
        isEveryOrderSelected={isEveryOrderSelected}
        items={selectedOrders}
        onClear={() => {
          setSelectedOrders([]);
          setisEveryOrderSelected(false);
        }}
        openNotificationDialog={() => setIsNotificationDialogOpen(true)}
      />
      <NotificationByOrdersDialog
        q={search}
        minDate={minDate ? formatISO(minDate) : undefined}
        maxDate={maxDate ? formatISO(maxDate) : undefined}
        isEveryOrderSelected={isEveryOrderSelected}
        isOpen={isNotificationDialogOpen}
        onClose={() => setIsNotificationDialogOpen(false)}
        selectedCompanies={selectedCompanies}
        selectedPaymentTypes={selectedPaymentTypes}
        selectedProducts={selectedProducts}
        selectedRestaurants={selectedRestaurants}
        selectedOrders={selectedOrders}
        selectedContentOrders={selectedContentOrders}
      />
    </PageContainer>
  );
};

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 50,
    border: 'none',
    borderRadius: 18,
    maxWidth: 509,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

export default OrdersList;
