import Axios, { AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const improvedConfig = {
    ...config,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      'x-api-resource': 'companies',
    },
  };
  const promise = AXIOS_INSTANCE({
    ...improvedConfig,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const AXIOS_INSTANCE_STG = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL_STG,
});

export const customInstanceStg = <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const improvedConfig = {
    ...config,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      'x-api-resource': 'companies',
    },
  };
  const promise = AXIOS_INSTANCE_STG({
    ...improvedConfig,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
