/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type NotificationDeliveryType = typeof NotificationDeliveryType[keyof typeof NotificationDeliveryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationDeliveryType = {
  PUSH: 'PUSH',
  MAIL: 'MAIL',
  PUSH_AND_MAIL: 'PUSH_AND_MAIL',
} as const;
