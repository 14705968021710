export interface RestaurantDTO {
  id: string;
  name: string;
  pictureUrl: string;
  hoverPictureUrl?: string;
  bannerPictureUrl?: string;
  mobileBannerPictureUrl?: string;
  paperformURL?: string;
  order?: number;
  restaurantType: RestaurantType;
}

export enum RestaurantType {
  UNKOWN = -1,
  B2C,
  B2B,
  BOTH,
}
