import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../../../../../../components/header';
import SaveButton from '../../../../../../../../components/header/components/save-button';
import { SAVE_BUTTON_TYPE } from '../../../../../../../../components/header/components/save-button/props';
import { UserType } from '../../../../../../../../dtos/userDTO';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import PageContainer from '../../../../../../components/page-container';
import UserInformation from './components/information';
import { employeeReducer, initialEmployee, UserActions } from './reducer';
import InvitationMail from './components/invitation';
import { useQuery } from 'react-query';
import EmployeeService from '../../../../../../../../services/EmployeeService';
import UserTypeSelect from './components/type';
import Modal from 'react-modal'
import { customStyles } from '../../../../../../components/popup/customStyle';
import Popup from '../../../../../../components/popup';
import PopupButton from '../../../../../../../../components/popup-button';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/delete-icon.svg'
import Form from '../../../../../../components/form';
import { usePopupContext } from '../../../../../../components/popup-context/context';
import { EnumPopupIndex } from '../../../../../../components/popup-context/props';
import { EmployeeDTO } from '../../../../../../../../dtos/employeeDTO';
import Service from '../../../../../../../../services/Service';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import BusyIndicator from '../../../../../../../../components/busy-indicator';

const UserDetail = () => {
    // Attributes
    const history = useHistory()
    const { translate } = useTranslation();
    const { employeeId, companyId }: any = useParams()
    const [employee, dispatch] = useReducer(employeeReducer, initialEmployee)
    const query = useQuery(`employee-${employeeId}`, refreshEmployee)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { showPopup } = usePopupContext()
    const [company, setCompany] = useState<any>({})

    // Effects
    useEffect(() => {
        if (employeeId && query.data) {

            dispatch({ payload: query.data, target: "self", type: UserActions.SERVER_CHANGED })
        }
    }, [employeeId, query.data])

    // Handlers
    async function refreshEmployee() {
        return (await EmployeeService.get(companyId, employeeId)).data;
    }

    function isUpdated() {
        if (query.data && employeeId) {

            for (const attribute in employee) {
                if (!Object.is(query.data[attribute], employee[attribute])) {
                    return true
                }
            }
        }
        return false
    };

    function renderUserRole() {
        switch (employee.user.userType) {
            case UserType.ADMIN:
                return 'Administrator';
            case UserType.EMPLOYEE:
                return 'Employee';

            case UserType.SUPERIOR:
                return 'Superior';

            case UserType.UNKNOWN:
                return ''
        }
    }

    async function handleSave() {

        for (const attribute in employee.user) {
            if (attribute === 'email' || attribute === 'firstName' || attribute === 'lastName') {
                if (employee.user[attribute] === '' || employee.user[attribute] === -1) {
                    showPopup(EnumPopupIndex.MANDATORY)
                    return
                }
            }
        }

        if (employee.employeeType === -1) {
            showPopup(EnumPopupIndex.MANDATORY);
            return;
        }

        let response;

        let newEmployee: EmployeeDTO = {
            id: employeeId ? employeeId : '',
            company: company,
            companyId: companyId,
            user: employee.user,
            userId: employee.userId,
            employeeType: employee.employeeType
        }

        if (newEmployee.id) {
            response = await EmployeeService.put(companyId, newEmployee);
        }
        else {
            response = await EmployeeService.post(companyId, newEmployee);
        }

        if (response) {
            if (newEmployee.id) {
                Service.success.push(translate('modif_employee_success'));
            }
            else {
                Service.success.push(translate('add_employee_success'));
            }
            history.goBack();
        }
    }

    async function handleDelete() {
        const response = await EmployeeService.deleteEmployee(companyId, employeeId);

        if (response) {
            Service.success.push(translate('delete_employee_success'));
            history.push(`/dashboard/companies/${companyId}/employees/`);
        }
    }

    return (
        <PageContainer>
            <Header
                title={employee.userId ? `${employee.user.firstName} ${employee.user.lastName}` : 'Add new user'}
                subTitle={renderUserRole()}
                hasBackButton={true}
                isUpdated={isUpdated()}
                saveButton={<SaveButton type={employee.userId ? SAVE_BUTTON_TYPE.SAVE : SAVE_BUTTON_TYPE.SAVE_AND_ADD} handleClick={handleSave} handleDelete={() => setIsModalOpen(true)} />}
            />
            <BackgroundDetailsForm>
                <BusyIndicator query={query}>
                    <Form>
                        <UserInformation user={employee.user} dispatch={dispatch} />
                        <UserTypeSelect type={employee.employeeType} dispatch={dispatch} />
                    </Form>
                    <InvitationMail />
                </BusyIndicator>
            </BackgroundDetailsForm>
            <Modal
                style={customStyles}
                isOpen={isModalOpen}
            >
                <Popup
                    title='Are you sure you want to delete this user?'
                    icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
                    buttons={[
                        <PopupButton backgroundColor='grey' handleClick={() => setIsModalOpen(false)} title='Cancel' />,
                        <PopupButton backgroundColor='yellow' handleClick={handleDelete} title='Yes, delete' />
                    ]}

                />
            </Modal>
        </PageContainer>
    )
}

export default UserDetail;