import React from 'react';
import Translation from '../../../translation';
import { SaveButtonProps, SAVE_BUTTON_TYPE } from './props';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import './style.css';

const SaveButton = ({ type, handleClick, handleDelete }: SaveButtonProps) => {
  // Handlers
  function handleSave() {
    handleClick();
  }

  return (
    <div className='save-delete-button-container'>
      {type === SAVE_BUTTON_TYPE.SAVE && (
        <DeleteIcon
          onClick={handleDelete}
          style={{ marginRight: 30, cursor: 'pointer' }}
        />
      )}
      <div className='save-button-container' onClick={handleSave}>
        <Translation>{type}</Translation>
      </div>
    </div>
  );
};

export default SaveButton;
