/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  NotificationPagingDTO,
  GetNotificationsParams,
  NotificationResDTO,
  CreateNotificationDTO,
  UpdateStatusNotificationDTO,
  UpdateNotificationDTO,
  CreateNotificationByOrderIdsDTO,
  CreateNotificationByOrderIdsParams
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getNotifications = (
    params: GetNotificationsParams,
 signal?: AbortSignal
) => {
      return customInstance<NotificationPagingDTO>(
      {url: `/v1/api/notifications`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetNotificationsQueryKey = (params: GetNotificationsParams,) => [`/v1/api/notifications`, ...(params ? [params]: [])];

    
export type GetNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getNotifications>>>
export type GetNotificationsQueryError = unknown

export const useGetNotifications = <TData = Awaited<ReturnType<typeof getNotifications>>, TError = unknown>(
 params: GetNotificationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotifications>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNotificationsQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotifications>>> = ({ signal }) => getNotifications(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getNotifications>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createNotification = (
    createNotificationDTO: CreateNotificationDTO,
 ) => {
      return customInstance<NotificationResDTO>(
      {url: `/v1/api/notifications`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createNotificationDTO
    },
      );
    }
  


    export type CreateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof createNotification>>>
    export type CreateNotificationMutationBody = CreateNotificationDTO
    export type CreateNotificationMutationError = unknown

    export const useCreateNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNotification>>, TError,{data: CreateNotificationDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNotification>>, {data: CreateNotificationDTO}> = (props) => {
          const {data} = props ?? {};

          return  createNotification(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createNotification>>, TError, {data: CreateNotificationDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getNotificationById = (
    notificationId: string,
 signal?: AbortSignal
) => {
      return customInstance<NotificationResDTO>(
      {url: `/v1/api/notifications/${notificationId}`, method: 'get', signal
    },
      );
    }
  

export const getGetNotificationByIdQueryKey = (notificationId: string,) => [`/v1/api/notifications/${notificationId}`];

    
export type GetNotificationByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationById>>>
export type GetNotificationByIdQueryError = unknown

export const useGetNotificationById = <TData = Awaited<ReturnType<typeof getNotificationById>>, TError = unknown>(
 notificationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNotificationByIdQueryKey(notificationId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationById>>> = ({ signal }) => getNotificationById(notificationId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getNotificationById>>, TError, TData>(queryKey, queryFn, {enabled: !!(notificationId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateStatusNotificationById = (
    notificationId: string,
    updateStatusNotificationDTO: UpdateStatusNotificationDTO,
 ) => {
      return customInstance<NotificationResDTO>(
      {url: `/v1/api/notifications/${notificationId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateStatusNotificationDTO
    },
      );
    }
  


    export type UpdateStatusNotificationByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateStatusNotificationById>>>
    export type UpdateStatusNotificationByIdMutationBody = UpdateStatusNotificationDTO
    export type UpdateStatusNotificationByIdMutationError = unknown

    export const useUpdateStatusNotificationById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStatusNotificationById>>, TError,{notificationId: string;data: UpdateStatusNotificationDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStatusNotificationById>>, {notificationId: string;data: UpdateStatusNotificationDTO}> = (props) => {
          const {notificationId,data} = props ?? {};

          return  updateStatusNotificationById(notificationId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateStatusNotificationById>>, TError, {notificationId: string;data: UpdateStatusNotificationDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const updateNotificationById = (
    notificationId: string,
    updateNotificationDTO: UpdateNotificationDTO,
 ) => {
      return customInstance<NotificationResDTO>(
      {url: `/v1/api/notifications/${notificationId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateNotificationDTO
    },
      );
    }
  


    export type UpdateNotificationByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateNotificationById>>>
    export type UpdateNotificationByIdMutationBody = UpdateNotificationDTO
    export type UpdateNotificationByIdMutationError = unknown

    export const useUpdateNotificationById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNotificationById>>, TError,{notificationId: string;data: UpdateNotificationDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNotificationById>>, {notificationId: string;data: UpdateNotificationDTO}> = (props) => {
          const {notificationId,data} = props ?? {};

          return  updateNotificationById(notificationId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateNotificationById>>, TError, {notificationId: string;data: UpdateNotificationDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const deleteNotification = (
    notificationId: string,
 ) => {
      return customInstance<NotificationResDTO>(
      {url: `/v1/api/notifications/${notificationId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotification>>>
    
    export type DeleteNotificationMutationError = unknown

    export const useDeleteNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError,{notificationId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotification>>, {notificationId: string}> = (props) => {
          const {notificationId} = props ?? {};

          return  deleteNotification(notificationId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteNotification>>, TError, {notificationId: string}, TContext>(mutationFn, mutationOptions);
    }
    export const createNotificationByOrderIds = (
    createNotificationByOrderIdsDTO: CreateNotificationByOrderIdsDTO,
    params?: CreateNotificationByOrderIdsParams,
 ) => {
      return customInstance<NotificationResDTO>(
      {url: `/v1/api/orders/notifications`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createNotificationByOrderIdsDTO,
        params
    },
      );
    }
  


    export type CreateNotificationByOrderIdsMutationResult = NonNullable<Awaited<ReturnType<typeof createNotificationByOrderIds>>>
    export type CreateNotificationByOrderIdsMutationBody = CreateNotificationByOrderIdsDTO
    export type CreateNotificationByOrderIdsMutationError = unknown

    export const useCreateNotificationByOrderIds = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNotificationByOrderIds>>, TError,{data: CreateNotificationByOrderIdsDTO;params?: CreateNotificationByOrderIdsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNotificationByOrderIds>>, {data: CreateNotificationByOrderIdsDTO;params?: CreateNotificationByOrderIdsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createNotificationByOrderIds(data,params,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createNotificationByOrderIds>>, TError, {data: CreateNotificationByOrderIdsDTO;params?: CreateNotificationByOrderIdsParams}, TContext>(mutationFn, mutationOptions);
    }
    