import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BusyIndicator from '../../../../../../components/busy-indicator';
import Header from '../../../../../../components/header';
import SaveButton from '../../../../../../components/header/components/save-button';
import { SAVE_BUTTON_TYPE } from '../../../../../../components/header/components/save-button/props';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import CompanyService from '../../../../../../services/CompanyService';
import BackgroundDetailsForm from '../../../../components/background-details-form';
import PageContainer from '../../../../components/page-container';
import Address from './components/address';
import CommercialInformation from './components/commercial-information';
import Delivery from './components/delivery';
import { actions, companyReducer, initialState } from './reducer';
import Modal from 'react-modal';
import { customStyles } from '../../../../components/popup/customStyle';
import Popup from '../../../../components/popup';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/delete-icon.svg';
import PopupButton from '../../../../../../components/popup-button';
import Form from '../../../../components/form';
import { usePopupContext } from '../../../../components/popup-context/context';
import { EnumPopupIndex } from '../../../../components/popup-context/props';
import Service from '../../../../../../services/Service';
import UploadPicture from '../../../restaurants/components/upload-picture';
import './style.css';
import OrderTiming from './components/order-timing';
import {
	useCreateCompany,
	useGetCompanyById,
	useUpdateCompanyById,
} from '../../../../../../services/go-api/company/company';
import { ClosingHoursType } from './components/order-closing-hours/props';
import OrderClosingHours from './components/order-closing-hours';
import KitchenPriority from './components/kitchenPriority';
import { add, format } from 'date-fns';

const CompanyDetail = () => {
	// Attributes
	const history = useHistory();
	const { translate } = useTranslation();
	const { companyId }: any = useParams();
	const query = useGetCompanyById(companyId, {
		query: { enabled: !!companyId },
	});
	const [company, dispatch] = useReducer(companyReducer, initialState);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { showPopup } = usePopupContext();
	const { mutateAsync: updateCompany } = useUpdateCompanyById(companyId);
	const { mutateAsync: createCompany } = useCreateCompany();

	// Effects
	useEffect(() => {
		if (query?.data && companyId) {
			const kitchenClosingHourBegin = query.data.kitchenClosingHourBegin
				? add(new Date(query.data.kitchenClosingHourBegin), {
						minutes: new Date(
							query.data.kitchenClosingHourBegin
						).getTimezoneOffset(),
				  })
				: null;
			const kitchenClosingHourEnd = query.data.kitchenClosingHourEnd
				? add(new Date(query.data.kitchenClosingHourEnd), {
						minutes: new Date(
							query.data.kitchenClosingHourEnd
						).getTimezoneOffset(),
				  })
				: null;

			dispatch({
				type: actions.serverChanged,
				payload: {
					...query?.data,
					kitchenClosingHourBegin,
					kitchenClosingHourEnd,
				},
				target: 'self',
			});
		}
	}, [companyId, query?.data]);

	// Handlers
	async function handleSave() {
		const form = document.querySelector('form');

		if (!form?.checkValidity()) {
			showPopup(EnumPopupIndex.MANDATORY);
			return;
		}

		if (
			company?.vatNumber &&
			(/[A-Za-z`!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~]/.test(company?.vatNumber) ||
				company.vatNumber.replaceAll('.', '').length !== 10)
		) {
			showPopup(EnumPopupIndex.VATNUMBER);
			return;
		}
		let response;

		if (companyId) {
			response = await updateCompany({
				companyId: companyId,
				data: {
					...company,
					kitchenClosingHourBegin: format(
						new Date(company.kitchenClosingHourBegin),
						'HH:mm'
					),
					kitchenClosingHourEnd: format(
						new Date(company.kitchenClosingHourEnd),
						'HH:mm'
					),
				},
			});
		} else {
			response = await createCompany({
				data: {
					...company,
					kitchenClosingHourBegin: format(
						new Date(company.kitchenClosingHourBegin),
						'HH:mm'
					),
					kitchenClosingHourEnd: format(
						new Date(company.kitchenClosingHourEnd),
						'HH:mm'
					),
				},
			});
		}

		if (response) {
			if (companyId) {
				Service.success.push(translate('modif_company_success'));
			} else {
				Service.success.push(translate('add_company_success'));
			}
			history.goBack();
		}
	}

	async function handleDelete() {
		const response = await CompanyService.deleteCompany(companyId);
		if (response) {
			Service.success.push(translate('delete_company_success'));
			history.push('/dashboard/companies');
		}
	}

	function handleChangePictureUrl(pictureUrl: string) {
		dispatch({
			type: actions.companyChanged,
			target: 'logo',
			payload: pictureUrl,
		});
	}

	function handleChangeMobilePictureUrl(mobilePicture: string) {
		dispatch({
			type: actions.companyChanged,
			target: 'mobileLogo',
			payload: mobilePicture,
		});
	}
	return (
		<PageContainer>
			<BusyIndicator queries={[query]}>
				<Header
					title={companyId ? company.name : translate('AddNewCompany')}
					hasBackButton={true}
					saveButton={
						<SaveButton
							type={
								companyId
									? SAVE_BUTTON_TYPE.SAVE
									: SAVE_BUTTON_TYPE.SAVE_AND_ADD
							}
							handleClick={handleSave}
							handleDelete={() => setIsModalOpen(true)}
						/>
					}
				/>
				<BackgroundDetailsForm>
					<Form>
						<div className='company-info-container'>
							<div className='company-info'>
								<CommercialInformation company={company} dispatch={dispatch} />
								<Address company={company} dispatch={dispatch} />

								<OrderTiming company={company} dispatch={dispatch} />
								<div className={`company-info-container`}>
									<OrderClosingHours
										type={ClosingHoursType.START}
										company={company}
										dispatch={dispatch}
									/>
									<OrderClosingHours
										type={ClosingHoursType.END}
										company={company}
										dispatch={dispatch}
									/>
								</div>
							</div>
							<div className='company-picture'>
								<UploadPicture
									size='large'
									pictureUrl={company?.logo ? company?.logo : ''}
									handleChangePictureUrl={handleChangePictureUrl}
								/>
								<UploadPicture
									title='uploadMobileLogo'
									size='large'
									pictureUrl={company.mobileLogo ? company.mobileLogo : ''}
									handleChangePictureUrl={handleChangeMobilePictureUrl}
								/>
								<Delivery company={company} dispatch={dispatch} />
								<KitchenPriority company={company} dispatch={dispatch} />
							</div>
						</div>
					</Form>
				</BackgroundDetailsForm>
			</BusyIndicator>

			<Modal style={customStyles} isOpen={isModalOpen}>
				<Popup
					title='Are you sure you want to delete this company?'
					icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
					buttons={[
						<PopupButton
							backgroundColor='grey'
							handleClick={() => setIsModalOpen(false)}
							title='Cancel'
						/>,
						<PopupButton
							backgroundColor='yellow'
							handleClick={handleDelete}
							title='Yes, delete'
						/>,
					]}
				/>
			</Modal>
		</PageContainer>
	);
};

export default CompanyDetail;
