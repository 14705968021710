import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAllProducts } from '../../../../../../../../../../services/go-api/product/product';
import BackgroundDetailsForm from '../../../../../../../../components/background-details-form';
import InputSelect from '../../../../../../../../components/input-select';
import TitleInputGroup from '../../../../../../../../components/title-input-group';
import SideDishCard from './card';
import { SideDishProps } from './props';
import './style.css';

const SideDishes = ({
	selectedSubProducts,
	setSelectedSubProducts,
}: SideDishProps) => {
	// Attributes
	const { productId }: any = useParams();
	const { data: allProducts } = useGetAllProducts({ size: 1000 });

	const options = useMemo(() => {
		if (allProducts && allProducts?.data)
			return (
				allProducts?.data
					// Filter options by removing the current product itself
					?.filter((p) => p.id !== productId)
					// Filter options by removing the selected products
					?.filter(
						(p) =>
							!selectedSubProducts
								?.map((subProd) => subProd.id)
								?.includes(p?.id ?? '')
					)
					.map((p) => ({
						label: p.name,
						value: p,
					}))
			);
		return [];
	}, [allProducts, productId, selectedSubProducts]);

	// Render
	return (
		<BackgroundDetailsForm style={{ minHeight: '12.5rem' }}>
			<TitleInputGroup title='Select side dishes' />
			<div style={{ marginTop: '1.5rem', width: '13.625rem' }}>
				<InputSelect
					initialValue={selectedSubProducts}
					options={options}
					handleSelect={(value) =>
						setSelectedSubProducts([...selectedSubProducts, value])
					}
				/>
			</div>
			<div className='side-dishes-selected'>
				{selectedSubProducts?.map((product, index) => (
					<SideDishCard
						key={`side_dish_${product?.id}_${index}`}
						product={product}
						handleRemoveClick={() =>
							setSelectedSubProducts(
								selectedSubProducts.filter((p) => p.id !== product.id)
							)
						}
					/>
				))}
			</div>
		</BackgroundDetailsForm>
	);
};

export default SideDishes;
