import React from "react";
import { useTable } from "react-table";
import { TableProps } from "./props";
import "./style.css";

const Table = ({ columns, data }: TableProps) => {
	// Attributes
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data: data,
		});

	// Render
	return (
		<table {...getTableProps()} className="table">
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							// Add the sorting props to control sorting. For this example
							// we can add them into the header props
							<th {...column.getHeaderProps()}>
								{column.render("Header")}
							</th>
						))}
					</tr>
				))}
			</thead>
			{/* Apply the table body props */}
			<tbody style={{ textAlign: "center" }} {...getTableBodyProps()}>
				{
					// Loop over the table rows
					rows.map((row) => {
						// Prepare the row for display
						prepareRow(row);
						return (
							// Apply the row props
							<tr {...row.getRowProps()}>
								{
									// Loop over the rows cells
									row.cells.map((cell) => {
										// Apply the cell props
										return (
											<td {...cell.getCellProps()}>
												{
													// Render the cell contents
													cell.render("Cell")
												}
											</td>
										);
									})
								}
							</tr>
						);
					})
				}
			</tbody>
		</table>
	);
};

export default Table;
