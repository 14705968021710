import React, { useMemo, useState } from "react";
import PageContainer from "../../../../../components/page-container";
import Lottie from "react-lottie";
import animationData from "../../list/assets/loading.json";
import "./style.css";
import Header from "../../../../../../../components/header";
import { useTranslation } from "../../../../../../../components/translation-context/TranslationContext";
import Search from "../../../../../components/search";
import MenuAction from "../../../../../components/menu-action";
import AddButton from "../../../../../../../components/add-button";
import { useHistory } from "react-router-dom";
import MenuListContainer from "../../../../../components/menu-list-container";
import { useGetNotificationTemplates } from "../../../../../../../services/go-api/notification-templates/notification-templates";
import { useTable } from "react-table";
import { ReactComponent as EditIcon } from "./edit.svg";
import TemplateDescriptionColumn from "./components/description-column";

const MAX_SIZE = 1000;

const NotificationsTemplates = () => {
  // Attributes
  const [search, setSearch] = useState<string>("");
  const { translate } = useTranslation();
  const history = useHistory();

  const {
    data: notificationsTemplate,
    isLoading,
    isSuccess,
  } = useGetNotificationTemplates({
    size: MAX_SIZE,
    q: search,
  });

  const templates = notificationsTemplate && notificationsTemplate.data;

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: "#1E1F20" }}>{value}</span>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }: { value: number }) => (
          <span style={{ color: "#1E1F20" }}>{value}</span>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }: { value: string }) => (
          <TemplateDescriptionColumn description={value} />
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }: { value: string }) => (
          <div
            style={{
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              history.push(
                `/dashboard/notifications/templates/${value}/details`
              )
            }
          >
            <EditIcon />
          </div>
        ),
      },
    ],
    [history]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: isSuccess ? notificationsTemplate.data ?? [] : [],
    });

  // Render
  return (
    <PageContainer>
      <Header
        title={translate("Notifications templates")}
        hasBackButton={true}
        goBackUrl={`/dashboard/notifications`}
        disableSaveButton={true}
      />
      <MenuListContainer>
        <MenuAction>
          <Search
            value={search}
            placeholder="Search for a template"
            onChange={setSearch}
          />
          <AddButton
            title="Add a new template"
            handleClick={() =>
              history.push(`/dashboard/notifications/templates/details`)
            }
          />
        </MenuAction>
        {isLoading ? (
          <tr className="notifications-table-placeholder-container">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={300}
              width={300}
            />
          </tr>
        ) : (
          <>
            {templates?.length && (
              <div className="templates-body-container">
                <table
                  {...getTableProps()}
                  className="templates-table-container"
                >
                  <thead className="template-table-header-container">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="template-table-header-row"
                      >
                        {headerGroup.headers.map((column) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <th
                            {...column.getHeaderProps()}
                            className="template-table-header-tuple"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {/* Apply the table body props */}
                  <tbody
                    {...getTableBodyProps()}
                    className="templates-table-body-container"
                  >
                    {
                      // Loop over the table rows
                      rows.map((row) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <tr
                            {...row.getRowProps()}
                            className="template-table-row-container"
                          >
                            {
                              // Loop over the rows cells
                              row.cells.map((cell) => {
                                // Apply the cell props
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    className="template-table-row-tuple"
                                  >
                                    {
                                      // Render the cell contents
                                      cell.render("Cell")
                                    }
                                  </td>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </MenuListContainer>
    </PageContainer>
  );
};

export default NotificationsTemplates;
