import {
  Priority,
  ProductResDTO,
  ProductTagResDTO,
} from "../../../../../../../../dtos/go-api";

export type ProductForm = ProductResDTO & {
  visibleTags: ProductTagResDTO[];
  invisibleTags: ProductTagResDTO[];
};

export const initialProduct: ProductForm = {
  name: "",
  unitPrice: 0,
  isAvailable: false,
  description: "",
  pictureUrl: "",
  promoDescription: "",
  priority: Priority.LOW,
  order: 0,
  visibleTags: [],
  invisibleTags: [],
};

interface ProductActionReducer {
  type: "serverChanged" | "productChanged";
  target: string;
  payload: any;
}

export const reducer = (state: ProductResDTO, action: ProductActionReducer) => {
  switch (action.type) {
    case "productChanged":
      return {
        ...state,
        [action.target]: action.payload,
      };
    case "serverChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
