import React, { useEffect, useMemo, useState } from "react";
import Search from "../../../../../../components/search";
import MultiSelectSearch from "./multi-select-search";
import { OrdersFiltersProps } from "./props";
import { Option } from "./multi-select-search/props";
import { CompanyResDTO, FoodizPaymentMethod, ProductResDTO, RestaurantResDTO } from "../../../../../../../../dtos/go-api";
import Download from "../assets/download";
import { getOrdersReport } from "../../../../../../../../services/go-api/order/order";
import { downloadCSV } from "../../../../../../../../utils";
import { toast } from "react-toastify";
import { formatISO } from "date-fns";
import Loading from "../assets/loading";
import "./style.css";
import Close from "../assets/close";
import Pager from "../../../../../../../../components/pager";
import { useGetAllCompanies } from "../../../../../../../../services/go-api/company/company";
import { useGetRestaurants } from "../../../../../../../../services/go-api/restaurant/restaurant";
import { useGetAllProducts } from "../../../../../../../../services/go-api/product/product";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import Select from "react-select";
import { customStyles, selectStyle } from "./style";

const orderRowKeys = ["id", "orderTicketId", "date", "time", "firstName", "lastName", "userEmail", "companyName", "restaurantName", "productName", "quantity", "indexPrice", "baseAmount", "refundAmount", "totalAmount", "promoCodeName", "promoCodeAmount", "promoCodeType", "paymentMethod"];

const orderCsvHeaders = ["Order Id", "Order Ticket Id", "Order Date", "Order Time", "First Name", "Last Name", "User Email", "Company Name", "Restaurant Name", "Product Name", "Quantity", "Index Price", "Base Amount", "Refund Amount", "Total Amount", "Promo Code Name", "Promo Code Amount", "Promo Code Type", "Payment Method"];

const OrdersFilters = ({ page, totalPages, setPage, selectedCompanies, setSelectedCompanies, selectedRestaurants, setSelectedRestaurants, selectedProducts, setSelectedProducts, selectedPaymentTypes, setSelectedPaymentTypes, search, setSearch, minDate, maxDate }: OrdersFiltersProps) => {
	// Attributes
	const { translate } = useTranslation();
	//const queryCompanies = useQuery('companies', refreshCompanies);
	const restaurantsReq = useGetRestaurants({ size: 1000 }, { query: { refetchOnWindowFocus: false } });
	const companiesReq = useGetAllCompanies({ size: 1000 }, { query: { refetchOnWindowFocus: false } });
	const productsReq = useGetAllProducts({ size: 1000 }, { query: { refetchOnWindowFocus: false } });
	const companies = companiesReq.data && companiesReq.data.data;

	const [searchCompany, setSearchCompany] = useState<string>("");
	const [searchRestaurant, setSearchRestaurant] = useState<string>("");
	const [searchPaymentType, setSearchPaymentType] = useState<string>("");

	const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

	const companyOptions = useMemo(() => {
		let filteredCompanies = companies;

		// Search filter
		if (searchCompany !== "") {
			const searchLower = searchCompany.toLowerCase();
			filteredCompanies = filteredCompanies?.filter((company: CompanyResDTO) => {
				return company?.name?.toLowerCase().includes(searchLower);
			});
		}

		return filteredCompanies?.map((company: CompanyResDTO) => {
			return {
				value: company.id,
				label: company.name,
			};
		}) as Option[];
	}, [searchCompany, companies]);

	const restaurantOptions = useMemo(() => {
		let restaurants: RestaurantResDTO[] = restaurantsReq?.data?.data ?? [];

		// Search filter
		if (searchRestaurant !== "") {
			const searchLower = searchRestaurant.toLowerCase();
			restaurants = restaurants?.filter((restaurant: RestaurantResDTO) => {
				return restaurant.name?.toLowerCase().includes(searchLower);
			});
		}

		if (restaurants?.length > 0) {
			return restaurants?.map((restaurant: RestaurantResDTO) => {
				return {
					value: restaurant.id,
					label: restaurant.name,
				};
			}) as Option[];
		} else {
			return [{}] as Option[];
		}
	}, [searchRestaurant, restaurantsReq?.data?.data]);

	const productOptions = useMemo(() => {
		let products: ProductResDTO[] = productsReq?.data?.data ?? [];

		return products?.map((product: ProductResDTO) => {
			return {
				value: product.id,
				label: product.name,
			};
		}) as Option[];
	}, [productsReq?.data?.data]);

	const paymentTypeOptions = useMemo(() => {
		let paymentTypes: FoodizPaymentMethod[] = Object.values(FoodizPaymentMethod);

		// Search filter
		if (searchPaymentType !== "") {
			const searchLower = searchPaymentType.toLowerCase();
			paymentTypes = paymentTypes?.filter((paymentType: FoodizPaymentMethod) => {
				return paymentType.toLowerCase().includes(searchLower);
			});
		}

		return paymentTypes?.map((paymentType: FoodizPaymentMethod) => {
			return {
				value: paymentType,
				label: paymentType,
			};
		}) as Option[];
	}, [searchPaymentType]);

	const hasFilters = useMemo(() => {
		return selectedCompanies?.length > 0 || selectedPaymentTypes?.length > 0 || selectedRestaurants?.length > 0 || selectedProducts?.length > 0 || search !== "";
	}, [selectedCompanies, selectedPaymentTypes, selectedRestaurants, selectedProducts, search]);

	// Handlers
	async function onExportOrders() {
		// Fetch orders data
		try {
			setIsExportLoading(true);
			const orders = await getOrdersReport({
				companyIds: selectedCompanies,
				restaurantIds: selectedRestaurants,
				paymentTypes: selectedPaymentTypes,
				q: search,
				minDate: minDate ? formatISO(minDate) : undefined,
				maxDate: maxDate ? formatISO(maxDate) : undefined,
			});

			downloadCSV(orderRowKeys, orderCsvHeaders, orders, `all-orders-on-${new Date().toISOString()}`);
			setIsExportLoading(false);
		} catch (err) {
			toast.error("Unable to export orders");
		}
	}

	function onSelectCompany(value: string) {
		const isChecked = selectedCompanies.includes(value);
		if (isChecked) {
			// Unselect company
			setSelectedCompanies((companies) => {
				const array = [...companies];
				const index = array?.findIndex((company) => company === value);
				array.splice(index, 1);
				return array;
			});
		} else {
			// Select company
			setSelectedCompanies((companies) => {
				const array = [...companies];
				array.push(value);
				return array;
			});
		}
		setPage && setPage(0);
	}

	function onSelectRestaurant(value: string) {
		const isChecked = selectedRestaurants.includes(value);
		if (isChecked) {
			// Unselect restaurant
			setSelectedRestaurants((restaurants) => {
				const array = [...restaurants];
				const index = array?.findIndex((restaurant) => restaurant === value);
				array.splice(index, 1);
				return array;
			});
		} else {
			// Select restaurant
			setSelectedRestaurants((restaurants) => {
				const array = [...restaurants];
				array.push(value);
				return array;
			});
		}
		setPage && setPage(0);
	}

	function onSelectPaymentTypes(value: FoodizPaymentMethod) {
		const isChecked = selectedPaymentTypes.includes(value);
		if (isChecked) {
			// Unselect payment type
			setSelectedPaymentTypes((paymentTypes) => {
				const array = [...paymentTypes];
				const index = array?.findIndex((paymentType) => paymentType === value);
				array.splice(index, 1);
				return array;
			});
		} else {
			// Select payment type
			setSelectedPaymentTypes((paymentTypes) => {
				const array = [...paymentTypes];
				array.push(value);
				return array;
			});
		}
		setPage && setPage(0);
	}

	function onResetFilters() {
		setSearch("");
		setSelectedCompanies([]);
		setSelectedRestaurants([]);
		setSelectedPaymentTypes([]);
		setSelectedProducts([]);
		setPage && setPage(0);
	}

	function getValueFromProducts() {
		if (selectedProducts.length > 0) {
			return { label: productOptions.find((option) => option?.value === selectedProducts[0])?.label, value: selectedProducts[0] };
		}

		return [];
	}

	useEffect(() => {
		if (selectedProducts === undefined) {
			setSelectedProducts([]);
		}
	}, [selectedProducts, setSelectedProducts]);

	// Render
	return (
		<div style={customStyles.container}>
			<div style={customStyles.column}>
				<div style={customStyles.filtersContainer}>
					<Search
						placeholder={translate("Search")}
						value={search}
						onChange={(value) => {
							setSearch(value);
							setPage && setPage(0);
						}}
						width="15.625rem"
						animation={false}
					/>
					<MultiSelectSearch name="companies" value={selectedCompanies} onChange={onSelectCompany} options={companyOptions} placeholder="Companies" searchPlaceholder="Search company" search={searchCompany} setSearch={setSearchCompany} />
					<MultiSelectSearch name="restaurants" value={selectedRestaurants} onChange={onSelectRestaurant} options={restaurantOptions} placeholder="Restaurants" searchPlaceholder="Search restaurant" search={searchRestaurant} setSearch={setSearchRestaurant} />
					<Select
						isClearable
						placeholder={translate("Product")}
						styles={selectStyle}
						value={getValueFromProducts()}
						options={productOptions}
						onChange={(event: any) => {
							setSelectedProducts(event && event.value ? [event.value] : []);
						}}
					/>
					<MultiSelectSearch name="paymentTypes" value={selectedPaymentTypes} onChange={onSelectPaymentTypes} options={paymentTypeOptions} placeholder="Payment Types" searchPlaceholder="Search payment type" search={searchPaymentType} setSearch={setSearchPaymentType} />
				</div>
				<div style={customStyles.filtersRightContainer}>
					<div style={customStyles.exportContainer} onClick={() => !isExportLoading && onExportOrders()}>
						<div style={customStyles.exportText}>Export CSV</div>
						<div style={customStyles.exportIconContainer}>{isExportLoading ? <Loading width="1.125rem" height="1.125rem" stroke="#000000" /> : <Download width="1.125rem" height="1.125rem" stroke="#000000" />}</div>
					</div>
					<Pager page={page} totalPages={totalPages} setPage={setPage} />
				</div>
			</div>
			{hasFilters && (
				<div style={customStyles.resetFiltersContainer} onClick={onResetFilters}>
					<Close width=".625rem" height=".625rem" fill="rgba(6, 26, 66, 1)" />
					<span style={customStyles.resetFiltersText}>Reset filters</span>
				</div>
			)}
		</div>
	);
};

export default OrdersFilters;
