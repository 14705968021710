import React from 'react';
import InputText from '../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../components/input-with-label';
import { RestaurantType } from '../../../../../../../../dtos/restaurantDTO';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import InputSelect from '../../../../../../components/input-select';
import { RestaurantInformationProps } from './props';
import TitleInputGroup from '../../../../../../components/title-input-group';
import './style.css';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';

const RestaurantInformation = ({
  restaurant,
  dispatch,
}: RestaurantInformationProps) => {
  //Attributes
  const options = [
    { value: RestaurantType.B2C, label: 'B2C' },
    { value: RestaurantType.B2B, label: 'B2B' },
    { value: RestaurantType.BOTH, label: 'BOTH' },
  ];
  const { translate } = useTranslation();

  //Functions
  function handleOrderChange(order: number) {
    dispatch({
      type: 'restaurantChanged',
      target: 'order',
      payload: Number(order),
    });
  }

  //Render
  return (
    <BackgroundDetailsForm style={{ width: '40%', marginRight: 40 }}>
      <TitleInputGroup title='Restaurant information' />
      <div className='restaurant-information-goup-input'>
        <InputWithLabel label='RESTAURANT NAME'>
          <InputText
            required={true}
            value={restaurant.name}
            placeholder='ex: Nightborn'
            size='medium'
            onChange={(event: any) =>
              dispatch({
                type: 'restaurantChanged',
                payload: event.target.value,
                target: 'name',
              })
            }
          />
        </InputWithLabel>
        <div style={{ marginTop: 20 }} />
        <InputWithLabel label='RESTAURANT ORDER'>
          <InputText
            value={restaurant.order}
            onChange={(event: any) => handleOrderChange(event.target.value)}
            placeholder={translate('restaurantOrderPlaceHolder')}
          />
        </InputWithLabel>
        <div style={{ marginTop: 20 }} />
        <InputWithLabel
          label='TYPE OF RESTAURANT'
          style={{ width: 'fit-content' }}
        >
          <InputSelect
            options={options}
            initialValue={restaurant.restaurantType}
            size='medium'
            handleSelect={(value) =>
              dispatch({
                type: 'restaurantChanged',
                target: 'restaurantType',
                payload: value,
              })
            }
          />
        </InputWithLabel>
        {(restaurant.restaurantType === RestaurantType.B2B ||
          restaurant.restaurantType === RestaurantType.BOTH) && (
          <>
            <div style={{ marginTop: 20 }} />
            <InputWithLabel label='PAPERFORM URL'>
              <InputText
                required={true}
                value={restaurant.paperformURL}
                placeholder='ex: http://paperform.url'
                size='medium'
                onChange={(event: any) =>
                  dispatch({
                    type: 'restaurantChanged',
                    payload: event.target.value,
                    target: 'paperformURL',
                  })
                }
              />
            </InputWithLabel>
          </>
        )}
      </div>
    </BackgroundDetailsForm>
  );
};

export default RestaurantInformation;
