import React, { useRef, useState } from 'react';
import Search from '../../../../../../components/search';
import { CompanyOrdersFiltersProps } from './props';
import { downloadCSV } from '../../../../../../../../utils';
import { toast } from 'react-toastify';
import Loading from '../assets/loading';
import './style.css';
import Close from '../assets/close';
import Pager from '../../../../../../../../components/pager';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { ReactComponent as DownloadCSV } from '../assets/downloadCSV.svg';

import { ReactComponent as FoodizIcon } from '../assets/foodiz.svg';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import useQueryString from '../../../../../../../../hooks/useQueryString';
import { EnumFilterTypes } from '../types';
import { TimeScale } from '../../../../../../../../types';

const companyOrderRowKeys = [
	'companyId',
	'companyName',
	'numberOfOrders',
	'companyAddress',
	'deliveryInfo',
	'zoneName',
	'kitchenPriority',
];

const companyOrderCsvHeaders = [
	'Company Id',
	'Company Name',
	'Number of Orders',
	'Company Address',
	'Delivery Info',
	'Zone Name',
	'Kitchen Priority',
];

const CompanyOrdersFilters = ({
	page,
	totalPages,
	setPage,
	search,
	setSearch,
	companies,
}: CompanyOrdersFiltersProps) => {
	// Attributes
	const timeframe = useQueryString().get(EnumFilterTypes.TIME_SCALE);
	const timeScale = timeframe as TimeScale;
	const { translate } = useTranslation();
	const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
	const componentRef = useRef(null);
	const handleReactPrint = useReactToPrint({
		content: () => componentRef?.current,
	});

	// Handlers
	async function onExportCompanyOrders() {
		try {
			setIsExportLoading(true);

			downloadCSV(
				companyOrderRowKeys,
				companyOrderCsvHeaders,
				companies,
				`company-orders-on-${new Date().toISOString()}`
			);
			setIsExportLoading(false);
		} catch (err) {
			toast.error('Unable to export orders');
			setIsExportLoading(false);
		}
	}

	function onResetFilters() {
		setSearch('');
		setPage && setPage(0);
	}
	// Render
	return (
		<div style={customStyles.container}>
			<div style={customStyles.row}>
				<div style={customStyles.filtersContainer}>
					<Search
						placeholder={translate('Search')}
						value={search}
						onChange={(value) => {
							setSearch(value);
							setPage && setPage(0);
						}}
						width='15.625rem'
						animation={false}
					/>
				</div>
				<div style={customStyles.filtersRightContainer}>
					<div
						style={customStyles.exportContainer}
						onClick={() => !isExportLoading && onExportCompanyOrders()}
					>
						<div style={customStyles.exportText}>Export CSV</div>
						<div style={customStyles.exportIconContainer}>
							{isExportLoading ? (
								<Loading width='1.125rem' height='1.125rem' stroke='#000000' />
							) : (
								<DownloadCSV
									width='1.125rem'
									height='1.125rem'
									stroke='#000000'
								/>
							)}
						</div>
					</div>
					{timeScale === TimeScale.Day && (
						<div
							style={{
								cursor: 'pointer',
								backgroundColor: '#F5C755',
								width: '1.875rem',
								height: '1.875rem',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '100%',
							}}
							onClick={handleReactPrint}
						>
							<div style={customStyles.exportIconContainer}>
								<DownloadIcon />
							</div>
						</div>
					)}
				</div>
			</div>
			{search !== '' && (
				<div
					style={customStyles.resetFiltersContainer}
					onClick={onResetFilters}
				>
					<Close width='.625rem' height='.625rem' fill='rgba(6, 26, 66, 1)' />
					<span style={customStyles.resetFiltersText}>
						{translate('Reset filters')}
					</span>
				</div>
			)}
			<div style={{ display: 'none' }}>
				<div ref={componentRef}>
					{companies?.map((company) => {
						return (
							<div className='count-orders-by-company-pdf-container'>
								<div className='count-orders-by-company-pdf-header'>
									<FoodizIcon width={97} height={58} />
									<div className='count-orders-by-company-pdf-header-route-kitchen'>
										<div className='count-orders-by-company-pdf-header-route'>
											<div className='count-orders-by-company-pdf-header-route-name'>{`${translate(
												'route-name'
											).toUpperCase()}:`}</div>
											<div className='count-orders-by-company-pdf-header-zone-name'>{`${
												company?.zoneName?.toUpperCase() ?? '-'
											}`}</div>
										</div>

										<div className='count-orders-by-company-pdf-header-kitchen-priority'>
											{company?.kitchenPriority ?? '/'}
										</div>
									</div>
								</div>
								<div className='count-orders-by-company-pdf-body'>
									<div className='count-orders-by-company-pdf-body-company-name'>
										{company?.companyName}
									</div>
									<div className='count-orders-by-company-pdf-body-orders-number'>
										<div className='count-orders-by-company-pdf-body-orders-number-count'>
											{company?.numberOfOrders ?? '/'}
										</div>
										<div>{translate('orders').toUpperCase()}</div>
									</div>
									<div className='count-orders-by-company-pdf-address'>
										{company?.companyAddress}
									</div>
									<>
										<div className='count-orders-by-company-pdf-delivery-information-title'>
											{translate('delivery-informations')}
										</div>
										<div className='count-orders-by-company-pdf-delivery-information-content'>
											{company?.deliveryInfo ?? translate('none')}
										</div>
									</>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const customStyles: any = {
	container: {
		margin: '2.875rem 2.5625rem 0 2.5625rem',
		display: 'flex',
		gap: '.9375rem',
		flexDirection: 'column',
	},
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: '3.625rem',
	},
	filtersContainer: {
		display: 'flex',
		height: '100%',
		gap: '1.125rem',
	},
	filtersRightContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '1.3125rem',
	},
	exportContainer: {
		display: 'flex',
		gap: '.75rem',
		alignItems: 'center',
		cursor: 'pointer',
	},
	exportText: {
		fontWeight: '500',
		fontSize: '10px',
		lineHeight: '15px',
		color: '#000000',
	},
	exportIconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '2.25rem',
		height: '2.25rem',
		backgroundColor: '#FFFFFF',
		borderRadius: '.5rem',
	},
	resetFiltersContainer: {
		display: 'flex',
		gap: '.5rem',
		alignItems: 'center',
		cursor: 'pointer',
		width: 'fit-content',
	},
	resetFiltersText: {
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '15px',
		color: 'rgba(6, 26, 66, 0.3)',
	},
};

export default CompanyOrdersFilters;
