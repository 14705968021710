import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as ArrowRightIcon } from "../../../../../../../../../src/assets/arrow-right.svg";
import ZoneCompanyTag from "./components/company-tag";
import { ZoneCardProps } from "./props";
import "./styles.css";
import Modal from "react-modal";
import { customStyles } from "../../../../../../components/popup/customStyle";
import ZoneCardModal from "./components/modal";

const ZoneCard = ({ zone }: ZoneCardProps) => {
  // Attributes
  const history = useHistory();
  const [openZoneModal, setOpenZoneModal] = useState(false);
  const numberOfCompanyIds = zone?.companyIds?.length;
  const slicedCompanyIds =
    numberOfCompanyIds && numberOfCompanyIds > 5
      ? zone?.companyIds?.slice(0, 5)
      : zone?.companyIds;
  const moreCompanyNumber =
    numberOfCompanyIds && numberOfCompanyIds > 5 ? numberOfCompanyIds - 5 : 0;
  return (
    <div className="zone-card-container">
      <div className="zone-card-header">
        <div className="zone-card-header-name">{zone.name}</div>
        <ArrowRightIcon
          style={{ cursor: "pointer", minWidth: 24 }}
          onClick={() => history.push(`/dashboard/zones/${zone.id}/details`)}
        />
      </div>
      <div className="zone-card-companies-container">
        {/* List of companies */}
        {slicedCompanyIds?.map((id, index, data) => (
          <ZoneCompanyTag id={id} />
        ))}
        {!!moreCompanyNumber && (
          <div
            onClick={() => setOpenZoneModal(true)}
            className="zone-card-more-number"
          >{`+ ${moreCompanyNumber}`}</div>
        )}
      </div>
      <Modal
        style={{
          ...customStyles,
          content: { ...customStyles.content, padding: 0 },
        }}
        isOpen={openZoneModal}
        onRequestClose={() => setOpenZoneModal(false)}
      >
        <ZoneCardModal zone={zone} closeModal={() => setOpenZoneModal(false)} />
      </Modal>
    </div>
  );
};

export default ZoneCard;
