import { capitalize } from "lodash";
import Barcode from "react-barcode";
import React from "react";
import logo from "../../assets/foodiz-icon.png";

interface PrintableOrderProps {
  order: any;
}

export default function OrderTicketFooter({ order }: PrintableOrderProps) {
  return (
    <div className="page-footer">
      <div className="page-footer-detail">
        <div className="page-footer-company-name">
          {capitalize(order?.companyName?.toLocaleLowerCase())}
        </div>
        <div className="page-footer-detail">
          <div>{order.orderTicketId ?? ""}</div>
          <div className="bullet"></div>
          <div>{order.zoneName ?? ""}</div>
          <div className="bullet"></div>
          <div>{order.kitchenPriority ?? ""}</div>
        </div>
      </div>
      <div className="page-footer-below">
        <div className="page-footer-slogan">
          <div className="page-footer-slogan-text">Homemade with love by</div>
          <img src={logo} width={28} alt="foodiz logo" />
        </div>
        <Barcode
          width={1}
          height={30}
          format="CODE128"
          displayValue={false}
          value={order.orderTicketId ?? ""}
        />
      </div>
    </div>
  );
}
