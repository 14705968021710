import React from 'react';
import { useHistory } from 'react-router-dom';
import ActionButton from '../../../../../../../../components/action-button';
import { RestaurantCardProps } from './props';
import './style.css'

const RestaurantCard = ({ restaurant }: RestaurantCardProps) => {

    // Attributes
    const history = useHistory()

    // Handlers
    function renderTags() {
        switch (restaurant.restaurantType) {
            case 0:
                return ['B2C']
            case 1:
                return ['B2B'];
            case 2:
                return ['B2C', 'B2B'];
            default:
                return []
        }
    }

    return (
        <div className='restaurant-card-container'>
            <div className="restaurant-card-picture-container">
                <img src={restaurant.pictureUrl} alt='restaurant' style={{ width: "255px", objectFit: "cover", height: '100%', minHeight: "149px", borderRadius: '8px 8px 0 0' }} />
                {restaurant.restaurantType !== -1 && (
                    <div className="restaurant-tag-group">
                        {renderTags().map((type, index) => {

                            if (restaurant.restaurantType + 1 > index) {
                                return (<div className={`restaurant-tag-${type}`}>{type}</div>);
                            }
                            return ''
                        })}
                    </div>
                )}
            </div>
            <div className='restaurant-card-footer-container'>
                <div className='restaurant-card-name'>{restaurant.name}</div>
                <div className='restaurant-card-action-buttons-container'>
                    <ActionButton name='dishes' handleAction={() => history.push(`/dashboard/restaurants/${restaurant.id}/dishes`)} />
                    <div style={{ marginRight: 16 }} />
                    <ActionButton name='details' handleAction={() => history.push(`/dashboard/restaurants/${restaurant.id}/details`)} />
                </div>

            </div>
        </div>
    )
}

export default RestaurantCard