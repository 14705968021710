import React, { useState } from 'react';
import Translation from '../translation';
import { SegmentMenuInterface } from './props';
import './styles.css'

const SegmentTab = ({ segments, handleChange }: SegmentMenuInterface) => {

    // Attributes
    const [isSelected, setIsSelected] = useState<null | number>(1)


    // Handlers
    function mapSegments() {
        return segments.map((segment, index) => {
            return (

                <div className='segment-item' onClick={() => handleOnClick(segment, index + 1)} style={index !== segments.length - 1 ? { marginRight: 55 } : {}}>
                    <Translation>
                        {segment}
                    </Translation>
                    { isSelected === index + 1 && <div className='segment-bar' />}
                </div>
            )

        })
    }

    function handleOnClick(segment: string, index: number) {
        setIsSelected(index)
        handleChange(segment)
    }

    return (
        <div className='segments-container'>
            {mapSegments()}
        </div>
    )
}

export default SegmentTab;