/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConfigurationDTO,
  GetConfigurationByOsAndVersionParams,
  ConfigurationPagingDTO,
  GetAllConfigurationsParams,
  ConfigurationStatusDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getConfigurationByOsAndVersion = (
    params: GetConfigurationByOsAndVersionParams,
 signal?: AbortSignal
) => {
      return customInstance<ConfigurationDTO>(
      {url: `/v1/api/configuration`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetConfigurationByOsAndVersionQueryKey = (params: GetConfigurationByOsAndVersionParams,) => [`/v1/api/configuration`, ...(params ? [params]: [])];

    
export type GetConfigurationByOsAndVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getConfigurationByOsAndVersion>>>
export type GetConfigurationByOsAndVersionQueryError = unknown

export const useGetConfigurationByOsAndVersion = <TData = Awaited<ReturnType<typeof getConfigurationByOsAndVersion>>, TError = unknown>(
 params: GetConfigurationByOsAndVersionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfigurationByOsAndVersion>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConfigurationByOsAndVersionQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfigurationByOsAndVersion>>> = ({ signal }) => getConfigurationByOsAndVersion(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getConfigurationByOsAndVersion>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getLatestConfiguration = (
    
 signal?: AbortSignal
) => {
      return customInstance<ConfigurationDTO>(
      {url: `/v1/api/configurations/latest`, method: 'get', signal
    },
      );
    }
  

export const getGetLatestConfigurationQueryKey = () => [`/v1/api/configurations/latest`];

    
export type GetLatestConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getLatestConfiguration>>>
export type GetLatestConfigurationQueryError = unknown

export const useGetLatestConfiguration = <TData = Awaited<ReturnType<typeof getLatestConfiguration>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLatestConfiguration>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLatestConfigurationQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestConfiguration>>> = ({ signal }) => getLatestConfiguration(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getLatestConfiguration>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getAllConfigurations = (
    params?: GetAllConfigurationsParams,
 signal?: AbortSignal
) => {
      return customInstance<ConfigurationPagingDTO>(
      {url: `/v1/api/configurations`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAllConfigurationsQueryKey = (params?: GetAllConfigurationsParams,) => [`/v1/api/configurations`, ...(params ? [params]: [])];

    
export type GetAllConfigurationsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllConfigurations>>>
export type GetAllConfigurationsQueryError = unknown

export const useGetAllConfigurations = <TData = Awaited<ReturnType<typeof getAllConfigurations>>, TError = unknown>(
 params?: GetAllConfigurationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllConfigurations>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllConfigurationsQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllConfigurations>>> = ({ signal }) => getAllConfigurations(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllConfigurations>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateConfigurationVersionStateByConfigurationId = (
    configurationId: string,
    configurationStatusDTO: ConfigurationStatusDTO,
 ) => {
      return customInstance<ConfigurationDTO>(
      {url: `/v1/api/configurations/${configurationId}/versionState`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: configurationStatusDTO
    },
      );
    }
  


    export type UpdateConfigurationVersionStateByConfigurationIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateConfigurationVersionStateByConfigurationId>>>
    export type UpdateConfigurationVersionStateByConfigurationIdMutationBody = ConfigurationStatusDTO
    export type UpdateConfigurationVersionStateByConfigurationIdMutationError = unknown

    export const useUpdateConfigurationVersionStateByConfigurationId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfigurationVersionStateByConfigurationId>>, TError,{configurationId: string;data: ConfigurationStatusDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateConfigurationVersionStateByConfigurationId>>, {configurationId: string;data: ConfigurationStatusDTO}> = (props) => {
          const {configurationId,data} = props ?? {};

          return  updateConfigurationVersionStateByConfigurationId(configurationId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateConfigurationVersionStateByConfigurationId>>, TError, {configurationId: string;data: ConfigurationStatusDTO}, TContext>(mutationFn, mutationOptions);
    }
    