import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../../../../components/header';
import SaveButton from '../../../../../../components/header/components/save-button';
import { SAVE_BUTTON_TYPE } from '../../../../../../components/header/components/save-button/props';
import RestaurantService from '../../../../../../services/RestaurantService';
import MenuContainer from '../../../../components/menu-container';
import PageContainer from '../../../../components/page-container';
import RestaurantInformation from './components/information';
import { initialRestaurant, reducer } from './reducer';
import './style.css';
import UploadPicture from '../../components/upload-picture';
import Modal from 'react-modal';
import { customStyles } from '../../../../components/popup/customStyle';
import Popup from '../../../../components/popup';
import PopupButton from '../../../../../../components/popup-button';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/delete-icon.svg';
import Form from '../../../../components/form';
import { usePopupContext } from '../../../../components/popup-context/context';
import { EnumPopupIndex } from '../../../../components/popup-context/props';
import Service from '../../../../../../services/Service';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import BusyIndicator from '../../../../../../components/busy-indicator';

const RestaurantDetail = () => {
  // Attributes
  const history = useHistory();
  const { translate } = useTranslation();
  const { restaurantId }: any = useParams();
  const query = useQuery(`restaurant-${restaurantId}`, refreshRestaurant);
  const [restaurant, dispatch] = useReducer(reducer, initialRestaurant);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showPopup } = usePopupContext();

  // Effects
  useEffect(() => {
    if (query.data && restaurantId) {
      dispatch({ type: 'serverChanged', target: 'self', payload: query.data });
    }
  }, [query.data]);

  // Handlers
  function isUpdated() {
    if (query.data && restaurantId) {
      for (const attribute in restaurant) {
        if (!Object.is(query.data[attribute], restaurant[attribute])) {
          return true;
        }
      }
    }
    return false;
  }

  async function refreshRestaurant() {
    return (await RestaurantService.get(restaurantId)).data;
  }

  async function handleSave() {
    for (const attribute in restaurant) {
      if (
        attribute === 'name' ||
        attribute === 'pictureUrl' ||
        attribute === 'restaurantType'
      ) {
        if (restaurant[attribute] === '' || restaurant[attribute] === -1) {
          showPopup(EnumPopupIndex.MANDATORY);
          return;
        }
      }
    }

    let response;

    if (restaurantId) {
      response = await RestaurantService.put(restaurant);
    } else {
      response = await RestaurantService.post(restaurant);
    }

    if (response) {
      if (restaurantId) {
        Service.success.push(translate('modif_restaurant_success'));
      } else {
        Service.success.push(translate('add_restaurant_success'));
      }

      history.goBack();
    }
  }

  function handleChangePictureUrl(pictureUrl: string) {
    dispatch({
      type: 'restaurantChanged',
      target: 'pictureUrl',
      payload: pictureUrl,
    });
  }

  function handleChangeHoverPictureUrl(hoverPictureUrl: string) {
    dispatch({
      type: 'restaurantChanged',
      target: 'hoverPictureUrl',
      payload: hoverPictureUrl,
    });
  }

  function handleChangeBannerPictureUrl(bannerPictureUrl: string) {
    dispatch({
      type: 'restaurantChanged',
      target: 'bannerPictureUrl',
      payload: bannerPictureUrl,
    });
  }

  function handleChangeMobileBannerPictureUrl(bannerPictureUrl: string) {
    dispatch({
      type: 'restaurantChanged',
      target: 'mobileBannerPictureUrl',
      payload: bannerPictureUrl,
    });
  }

  async function handleDelete() {
    const response = await RestaurantService.deleteRestaurant(restaurantId);

    if (response) {
      Service.success.push(translate('delete_restaurant_success'));
      history.push('/dashboard/restaurants/');
    }
  }

  return (
    <PageContainer>
      <Header
        title={restaurantId ? restaurant.name : 'Add new restaurant'}
        isUpdated={isUpdated()}
        hasBackButton={true}
        saveButton={
          <SaveButton
            handleClick={handleSave}
            type={
              restaurantId
                ? SAVE_BUTTON_TYPE.SAVE
                : SAVE_BUTTON_TYPE.SAVE_AND_ADD
            }
            handleDelete={() => setIsModalOpen(true)}
          />
        }
      />
      <MenuContainer>
        <BusyIndicator query={query}>
          <Form>
            <div
              style={{ flexWrap: 'wrap' }}
              className='restaurant-details-form-container'
            >
              <RestaurantInformation
                restaurant={restaurant}
                dispatch={dispatch}
              />
              <UploadPicture
                pictureUrl={restaurant.pictureUrl}
                handleChangePictureUrl={handleChangePictureUrl}
              />
              <UploadPicture
                title='uploadHoverImage'
                pictureUrl={restaurant.hoverPictureUrl}
                handleChangePictureUrl={handleChangeHoverPictureUrl}
              />
              <UploadPicture
                title='uploadBannerImage'
                pictureUrl={restaurant.bannerPictureUrl}
                handleChangePictureUrl={handleChangeBannerPictureUrl}
              />
              <UploadPicture
                title='uploadMobileBannerImage'
                pictureUrl={restaurant.mobileBannerPictureUrl}
                handleChangePictureUrl={handleChangeMobileBannerPictureUrl}
              />
            </div>
          </Form>
        </BusyIndicator>
      </MenuContainer>
      <Modal style={customStyles} isOpen={isModalOpen}>
        <Popup
          title='Are you sure you want to delete this restaurant?'
          icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
          buttons={[
            <PopupButton
              backgroundColor='grey'
              handleClick={() => setIsModalOpen(false)}
              title='Cancel'
            />,
            <PopupButton
              backgroundColor='yellow'
              handleClick={handleDelete}
              title='Yes, delete'
            />,
          ]}
        />
      </Modal>
    </PageContainer>
  );
};

export default RestaurantDetail;
