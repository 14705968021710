/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  RestaurantResDTO,
  RestaurantReqUpdateDTO,
  RestaurantPagingResDTO,
  GetRestaurantsParams,
  RestaurantReqCreateDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getRestaurantById = (
    restaurantId: string,
 signal?: AbortSignal
) => {
      return customInstance<RestaurantResDTO>(
      {url: `/v1/api/restaurants/${restaurantId}`, method: 'get', signal
    },
      );
    }
  

export const getGetRestaurantByIdQueryKey = (restaurantId: string,) => [`/v1/api/restaurants/${restaurantId}`];

    
export type GetRestaurantByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRestaurantById>>>
export type GetRestaurantByIdQueryError = unknown

export const useGetRestaurantById = <TData = Awaited<ReturnType<typeof getRestaurantById>>, TError = unknown>(
 restaurantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRestaurantById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRestaurantByIdQueryKey(restaurantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRestaurantById>>> = ({ signal }) => getRestaurantById(restaurantId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getRestaurantById>>, TError, TData>(queryKey, queryFn, {enabled: !!(restaurantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateRestaurantById = (
    restaurantId: string,
    restaurantReqUpdateDTO: RestaurantReqUpdateDTO,
 ) => {
      return customInstance<RestaurantResDTO>(
      {url: `/v1/api/restaurants/${restaurantId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: restaurantReqUpdateDTO
    },
      );
    }
  


    export type UpdateRestaurantByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateRestaurantById>>>
    export type UpdateRestaurantByIdMutationBody = RestaurantReqUpdateDTO
    export type UpdateRestaurantByIdMutationError = unknown

    export const useUpdateRestaurantById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRestaurantById>>, TError,{restaurantId: string;data: RestaurantReqUpdateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRestaurantById>>, {restaurantId: string;data: RestaurantReqUpdateDTO}> = (props) => {
          const {restaurantId,data} = props ?? {};

          return  updateRestaurantById(restaurantId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateRestaurantById>>, TError, {restaurantId: string;data: RestaurantReqUpdateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const deleteRestaurantById = (
    restaurantId: string,
 ) => {
      return customInstance<RestaurantResDTO>(
      {url: `/v1/api/restaurants/${restaurantId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteRestaurantByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRestaurantById>>>
    
    export type DeleteRestaurantByIdMutationError = unknown

    export const useDeleteRestaurantById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRestaurantById>>, TError,{restaurantId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRestaurantById>>, {restaurantId: string}> = (props) => {
          const {restaurantId} = props ?? {};

          return  deleteRestaurantById(restaurantId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteRestaurantById>>, TError, {restaurantId: string}, TContext>(mutationFn, mutationOptions);
    }
    export const getRestaurants = (
    params?: GetRestaurantsParams,
 signal?: AbortSignal
) => {
      return customInstance<RestaurantPagingResDTO>(
      {url: `/v1/api/restaurants`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetRestaurantsQueryKey = (params?: GetRestaurantsParams,) => [`/v1/api/restaurants`, ...(params ? [params]: [])];

    
export type GetRestaurantsQueryResult = NonNullable<Awaited<ReturnType<typeof getRestaurants>>>
export type GetRestaurantsQueryError = unknown

export const useGetRestaurants = <TData = Awaited<ReturnType<typeof getRestaurants>>, TError = unknown>(
 params?: GetRestaurantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRestaurants>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRestaurantsQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRestaurants>>> = ({ signal }) => getRestaurants(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getRestaurants>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createRestaurant = (
    restaurantReqCreateDTO: RestaurantReqCreateDTO,
 ) => {
      return customInstance<RestaurantResDTO>(
      {url: `/v1/api/restaurants`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: restaurantReqCreateDTO
    },
      );
    }
  


    export type CreateRestaurantMutationResult = NonNullable<Awaited<ReturnType<typeof createRestaurant>>>
    export type CreateRestaurantMutationBody = RestaurantReqCreateDTO
    export type CreateRestaurantMutationError = unknown

    export const useCreateRestaurant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRestaurant>>, TError,{data: RestaurantReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRestaurant>>, {data: RestaurantReqCreateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createRestaurant(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createRestaurant>>, TError, {data: RestaurantReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    