import React from "react";
import InputText from "../../../../../../../../../../components/input-text";
import InputWithLabel from "../../../../../../../../../../components/input-with-label";
import TextArea from "../../../../../../../../../../components/text-area";
import { Priority } from "../../../../../../../../../../dtos/go-api";
import BackgroundDetailsForm from "../../../../../../../../components/background-details-form";
import InputPrice from "../../../../../../../../components/input-price";
import InputSelect from "../../../../../../../../components/input-select";
import TitleInputGroup from "../../../../../../../../components/title-input-group";
import { ProductInformationProps } from "./props";
import "./style.css";

const ProductInformation = ({ product, dispatch }: ProductInformationProps) => {
  const options = [
    { value: Priority.LOW, label: "Low" },
    { value: Priority.MEDIUM, label: "Medium" },
    { value: Priority.HIGH, label: "High" },
  ];

  return (
    <BackgroundDetailsForm>
      <TitleInputGroup title="Dish information" />
      <div className="product-information-goup-input">
        <InputWithLabel label="DISH NAME">
          <InputText
            placeholder="insert dish name"
            required={true}
            value={product.name}
            onChange={(event: any) =>
              dispatch({
                type: "productChanged",
                target: "name",
                payload: event.target.value,
              })
            }
            style={{ marginRight: 30 }}
          />
        </InputWithLabel>
        <InputWithLabel label="DEFINE A PRICE">
          <InputPrice
            removeZero={() =>
              dispatch({
                type: "productChanged",
                target: "unitPrice",
                payload: "",
              })
            }
            required={true}
            value={product.unitPrice}
            onChange={(event: any) =>
              dispatch({
                type: "productChanged",
                target: "unitPrice",
                payload: event.target.value,
              })
            }
          />
        </InputWithLabel>
      </div>
      <InputWithLabel label="DISH DESCRIPTION">
        <TextArea
          required={true}
          placeholder="ex: Scottish salomon with guacamole"
          size="fill"
          value={product.description}
          onChange={(event: any) =>
            dispatch({
              type: "productChanged",
              target: "description",
              payload: event.target.value,
            })
          }
        />
      </InputWithLabel>
      <div style={{ marginTop: 21, width: 272 }}>
        <InputWithLabel label="DISH ORDER">
          <InputText
            placeholder="insert dish order"
            required={false}
            value={product.order}
            onChange={(event: any) =>
              dispatch({
                type: "productChanged",
                target: "order",
                payload: Number(event.target.value),
              })
            }
            style={{ marginRight: 30 }}
          />
        </InputWithLabel>
        <div style={{ marginTop: 21, width: 272 }}></div>
        <InputWithLabel label="DISH PRIORITY">
          <InputSelect
            initialValue={product.priority}
            options={options}
            handleSelect={(value) =>
              dispatch({
                type: "productChanged",
                target: "priority",
                payload: value,
              })
            }
          />
        </InputWithLabel>
      </div>
    </BackgroundDetailsForm>
  );
};

export default ProductInformation;
