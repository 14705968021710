import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Translation from '../../../../../../../../components/translation';
import { ReactComponent as DropzoneIcon } from './assets/dropzone.svg';
import FileService from '../../../../../../../../services/FileService';
import { DropzoneProps } from './props';
import './style.css';
import UploadedPicture from './components/picture';
import { resizeFile } from '../../../../../../../../utils/index';

const Dropzone = ({ pictureUrl, handleChangePictureUrl }: DropzoneProps) => {
  // Attributes
  const onDrop = useCallback(async (acceptedFiles) => {
    // extension
    const extensionIndex = (acceptedFiles[0].name as string).lastIndexOf('.');

    // resize file
    const encodedFile = await resizeFile(acceptedFiles[0]);

    // pictureDTO
    let pictureDTO = {
      id: '',
      creationDate: '',
      modificationDate: '',
      file: encodedFile.split(',')[1],
      fileName: acceptedFiles[0].name,
      fileExtension: (acceptedFiles[0].name as string).substr(extensionIndex),
      fileURL: '',
    };

    pictureDTO = await FileService.post(pictureDTO);

    handleChangePictureUrl(pictureDTO.fileURL);
  }, [handleChangePictureUrl]);

  const { getInputProps, getRootProps } = useDropzone({ onDrop });

  // handler
  function handleDelete() {
    handleChangePictureUrl('');
  }

  return (
    <>
      {pictureUrl ? (
        <UploadedPicture pictureUrl={pictureUrl} handleDelete={handleDelete} />
      ) : (
        <div {...getRootProps({ style: { outline: 'none', marginTop: 14 } })}>
          <input {...getInputProps()} />
          <div className='data-picture-dropzone-container'>
            <DropzoneIcon />
            <div className='data-picture-dropzone-title'>
              <Translation>Drag and drop image here</Translation>
            </div>
            <div className='data-picture-dropzone-subTitle'>
              <Translation>or browse files on your computer</Translation>
            </div>
            <div className='data-picture-dropzone-types'>
              <Translation>File should be PNG or JPEG, 1280 x 720</Translation>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dropzone;
