/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CompanyResDTO,
  CompanyReqUpdateDTO,
  CompanyPagingResDTO,
  GetAllCompaniesParams,
  CompanyReqCreateDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getCompanyById = (
    companyId: string,
 signal?: AbortSignal
) => {
      return customInstance<CompanyResDTO>(
      {url: `/v1/api/companies/${companyId}`, method: 'get', signal
    },
      );
    }
  

export const getGetCompanyByIdQueryKey = (companyId: string,) => [`/v1/api/companies/${companyId}`];

    
export type GetCompanyByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyById>>>
export type GetCompanyByIdQueryError = unknown

export const useGetCompanyById = <TData = Awaited<ReturnType<typeof getCompanyById>>, TError = unknown>(
 companyId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompanyById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompanyByIdQueryKey(companyId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyById>>> = ({ signal }) => getCompanyById(companyId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCompanyById>>, TError, TData>(queryKey, queryFn, {enabled: !!(companyId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateCompanyById = (
    companyId: string,
    companyReqUpdateDTO: CompanyReqUpdateDTO,
 ) => {
      return customInstance<CompanyResDTO>(
      {url: `/v1/api/companies/${companyId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: companyReqUpdateDTO
    },
      );
    }
  


    export type UpdateCompanyByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateCompanyById>>>
    export type UpdateCompanyByIdMutationBody = CompanyReqUpdateDTO
    export type UpdateCompanyByIdMutationError = unknown

    export const useUpdateCompanyById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCompanyById>>, TError,{companyId: string;data: CompanyReqUpdateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCompanyById>>, {companyId: string;data: CompanyReqUpdateDTO}> = (props) => {
          const {companyId,data} = props ?? {};

          return  updateCompanyById(companyId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateCompanyById>>, TError, {companyId: string;data: CompanyReqUpdateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const deleteCompanyById = (
    companyId: string,
 ) => {
      return customInstance<CompanyResDTO>(
      {url: `/v1/api/companies/${companyId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCompanyByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyById>>>
    
    export type DeleteCompanyByIdMutationError = unknown

    export const useDeleteCompanyById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyById>>, TError,{companyId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyById>>, {companyId: string}> = (props) => {
          const {companyId} = props ?? {};

          return  deleteCompanyById(companyId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteCompanyById>>, TError, {companyId: string}, TContext>(mutationFn, mutationOptions);
    }
    export const getAllCompanies = (
    params?: GetAllCompaniesParams,
 signal?: AbortSignal
) => {
      return customInstance<CompanyPagingResDTO>(
      {url: `/v1/api/companies`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAllCompaniesQueryKey = (params?: GetAllCompaniesParams,) => [`/v1/api/companies`, ...(params ? [params]: [])];

    
export type GetAllCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCompanies>>>
export type GetAllCompaniesQueryError = unknown

export const useGetAllCompanies = <TData = Awaited<ReturnType<typeof getAllCompanies>>, TError = unknown>(
 params?: GetAllCompaniesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllCompaniesQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCompanies>>> = ({ signal }) => getAllCompanies(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createCompany = (
    companyReqCreateDTO: CompanyReqCreateDTO,
 ) => {
      return customInstance<CompanyResDTO>(
      {url: `/v1/api/companies`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: companyReqCreateDTO
    },
      );
    }
  


    export type CreateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof createCompany>>>
    export type CreateCompanyMutationBody = CompanyReqCreateDTO
    export type CreateCompanyMutationError = unknown

    export const useCreateCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompany>>, TError,{data: CompanyReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompany>>, {data: CompanyReqCreateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createCompany(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createCompany>>, TError, {data: CompanyReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    