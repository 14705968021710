import React, { createRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import { OrderDTO } from '../../../../../../../../dtos/orderDTO';
import MenuContainer from '../../../../../../components/menu-container';
import OrderClientInformationField from '../../../detail/components/client-information-field';
import OrderCard from '../../../detail/components/order-card';
import OrderTable from '../../../detail/components/table';
import { formatLocationAddress } from '../../../detail/utils';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { ReactComponent as EmailIcon } from '../../assets/email.svg';
import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import { ReactComponent as CompanyIcon } from '../../assets/company.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';
import { ReactComponent as PrinterIcon } from '../../assets/print-big.svg';
import OrderLogService from '../../../../../../../../services/OrderLogService';
import { OrderLogType } from '../../../../../../../../dtos/orderLogDTO';
import Modal from 'react-modal';
import PopupButton from '../../../../../../../../components/popup-button';
import Popup from '../../../../../../components/popup';
import { ReactComponent as FoodizIcon } from '../assets/foodiz.svg';

export interface PrintableOrderProps {
  order: OrderDTO;
  setAllOrdersQueryEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrintableOrder = ({
  order,
  setAllOrdersQueryEnabled,
}: PrintableOrderProps) => {
  //Attributes
  const { translate } = useTranslation();
  const employee = order?.basketDTO?.employeeDTO;
  const componentRef = createRef<HTMLDivElement>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleReactPrint = useReactToPrint({
    onBeforePrint: () =>
      setAllOrdersQueryEnabled && setAllOrdersQueryEnabled(false),
    content: () => componentRef?.current,
    
    onAfterPrint: () => setIsModalOpen(true),
  });

  //Functions
  async function handleOrderLogUpdate() {
    //create orderLogd
    await OrderLogService.createOrderLog({
      orderId: order.id,
      orderLogType: OrderLogType.Printed,
    });

    //close modal
    setIsModalOpen(false);

    //enable all-orders query to refetch
    setAllOrdersQueryEnabled && setAllOrdersQueryEnabled(true);
  }

  return (
    <div
      style={{ cursor: 'pointer', display: 'block' }}
      onClick={handleReactPrint}
    >
      <PrinterIcon />
      <div style={{ display: 'none' }}>
        <MenuContainer ref={componentRef}>
          <div
            style={{
              width: '100%',
              height: '100px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FoodizIcon width={97} height={58} />
          </div>
          <div>
            <OrderCard
              title={`${translate('order-information')} (${order.id})`}
            >
              <OrderTable basketProducts={order.basketDTO?.basketProducts} />
            </OrderCard>
          </div>

          <div style={{ marginTop: '16px' }}>
            <div className='order-detail-container-right'>
              <div className='order-detail-container-right-top'>
                <OrderCard title={translate('client-information')}>
                  <div className='order-client-information-container'>
                    <div>
                      <OrderClientInformationField
                        title={translate('client-name')}
                        value={
                          employee?.user?.firstName && employee?.user?.lastName
                            ? `${employee?.user.firstName} ${employee?.user.lastName}`
                            : ''
                        }
                        icon={<UserIcon />}
                        customValueStyle={{ fontSize: 16, fontWeight: 'bold' }}
                      />
                      <OrderClientInformationField
                        title={translate('email-address')}
                        value={employee?.user?.email}
                        icon={<EmailIcon />}
                        customStyle={{ marginTop: '31px' }}
                      />
                      <OrderClientInformationField
                        title={translate('company-address')}
                        value={
                          order.addressDTO
                            ? formatLocationAddress(order.addressDTO)
                            : ''
                        }
                        icon={<LocationIcon />}
                        customStyle={{ marginTop: '31px' }}
                      />
                    </div>
                    <div style={{ marginLeft: '60px' }}>
                      <OrderClientInformationField
                        title={translate('company')}
                        value={employee?.company?.name}
                        icon={<CompanyIcon />}
                        customValueStyle={{ fontSize: 16, fontWeight: 'bold' }}
                      />

                      <OrderClientInformationField
                        title={translate('phone-number')}
                        value={employee?.user?.phoneNumber}
                        icon={<PhoneIcon />}
                        customStyle={{ marginTop: '31px' }}
                      />
                    </div>
                  </div>
                </OrderCard>
              </div>
            </div>
          </div>

          <OrderCard title={translate('order-comment-title')}>
            <div className='order-detail-comment'>{order && order.comment}</div>
          </OrderCard>
        </MenuContainer>
      </div>
      <Modal ariaHideApp={false} style={customStyles} isOpen={isModalOpen}>
        <Popup
          title='didOrderPrint'
          icon={<PrinterIcon style={{ width: 66, height: 69 }} />}
          buttons={[
            <PopupButton
              backgroundColor='grey'
              handleClick={(event: any) => {
                setIsModalOpen(false);
                event.stopPropagation();
              }}
              title='NO'
            />,
            <PopupButton
              backgroundColor='yellow'
              handleClick={(e: any) => {
                handleOrderLogUpdate();
                e.stopPropagation();
              }}
              title='YES'
            />,
          ]}
        />
      </Modal>
    </div>
  );
};

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 50,
    border: 'none',
    borderRadius: 18,
    maxWidth: 509,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

export default PrintableOrder;
