import {
  RestaurantDTO,
  RestaurantType,
} from '../../../../../../dtos/restaurantDTO';

export const initialRestaurant: RestaurantDTO = {
  id: '',
  name: '',
  pictureUrl: '',
  hoverPictureUrl: '',
  bannerPictureUrl: '',
  mobileBannerPictureUrl: '',
  paperformURL: '',
  order: 0,
  restaurantType: RestaurantType.UNKOWN,
};

interface RestaurantActionReducer {
  type: 'serverChanged' | 'restaurantChanged';
  target: string;
  payload: any;
}

export const reducer = (
  state: RestaurantDTO,
  action: RestaurantActionReducer
) => {
  switch (action.type) {
    case 'restaurantChanged':
      return {
        ...state,
        [action.target]: action.payload,
      };
    case 'serverChanged':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
