import React from 'react'
import Translation from '../translation';
import { PopupButtonProps } from './props';
import './style.css'

const PopupButton = ({ title, handleClick, backgroundColor, disabled }: PopupButtonProps) => {
    return (
        <div className={`popup-button ${backgroundColor} ${disabled}`} onClick={handleClick}>
            <Translation>{title}</Translation>
        </div>
    )
}

export default PopupButton;