import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import AddButton from '../../../../../../../../components/add-button';
import BusyIndicator from '../../../../../../../../components/busy-indicator';
import Header from '../../../../../../../../components/header';
import SegmentTab from '../../../../../../../../components/segment-tab';
import { ProductDTO } from '../../../../../../../../dtos/productDTO';
import useSearch from '../../../../../../../../hooks/use-search';
import useSegment from '../../../../../../../../hooks/use-segment';
import ProductService from '../../../../../../../../services/ProductService';
import RestaurantService from '../../../../../../../../services/RestaurantService';
import CardGroup from '../../../../../../components/card-group';
import EmptyStates from '../../../../../../components/empty-states';
import MenuAction from '../../../../../../components/menu-action';
import MenuListContainer from '../../../../../../components/menu-list-container';
import PageContainer from '../../../../../../components/page-container';
import Search from '../../../../../../components/search';
import DishCard from './components/dish-card';

const DishesList = () => {

    // Attributes
    const history = useHistory()
    const { restaurantId }: any = useParams()
    const restaurantQuery = useQuery(`restaurant-${restaurantId}`, refreshRestaurant)
    const query = useQuery('products', refreshProducts)
    const { search, filtered, setSearch } = useSearch<any>(query.data ? query.data : [], ["name"]);
    const { setSegment, segmented } = useSegment(filtered, handleSegment, 'Active')
    const [restaurant, setRestaurant] = useState(restaurantQuery.data ? restaurantQuery.data : '')

    // Effects
    useEffect(() => {
        if (restaurantQuery.data) {
            setRestaurant(restaurantQuery.data)
        }
    }, [restaurantQuery.data])

    // Handlers
    async function refreshProducts() {
        return (await ProductService.get(restaurantId)).data;
    }

    async function refreshRestaurant() {
        return (await RestaurantService.get(restaurantId)).data;
    }

    function handleSegment(dish: ProductDTO, segment: any) {
        const { isAvailable } = dish
        const segmentValue = mapSegmentWithValue(segment)
        return segmentValue === isAvailable
    }

    function handleFilter() {

        if (search) {
            return filtered.map((product) => <DishCard dish={product} refetch={query.refetch} key={`${product.id}`} />)
        }
        return segmented.map((product) => {
            return <DishCard dish={product} refetch={query.refetch} key={`${product.id}`} />
        })
    }

    function mapSegmentWithValue(segment: string) {
        switch (segment) {
            case 'Active':
                return true;
            case 'Offline':
                return false;
        }
    }

    return (
        <BusyIndicator query={query}>
            <PageContainer>
                <Header
                    title={`${restaurant.name} - Dishes`}
                    hasBackButton={true}
                    navigationHeader={[{ path: '/dashboard/restaurants', name: 'Restautrants' }, { name: `${restaurant.name}` }]}
                    actionButtons={{ menu: 'restaurants', routes: ['dishes', 'details'], id: restaurantId }}
                />
                {query.data && query.data.length > 0 ?
                    (
                        <MenuListContainer>
                            <MenuAction>
                                <Search value={search} placeholder='Search for a dish' onChange={setSearch} />
                                <SegmentTab segments={['Active', 'Offline']} handleChange={(segment: string) => setSegment(segment)} />
                                <AddButton title='Add a new dish' handleClick={() => history.push(`/dashboard/restaurants/${restaurantId}/dishes/details`)} />
                            </MenuAction>
                            <CardGroup>
                                {query?.data && handleFilter()}
                            </CardGroup>
                        </MenuListContainer>
                    )
                    :
                    (
                        <EmptyStates menu='dishes' restaurantId={restaurantId} />
                    )
                }
            </PageContainer>
        </BusyIndicator>
    )
}

export default DishesList;