import React, { useState } from 'react';
import ActionButtonProps, { ACTION_ICON } from './props';
import Icons from './assets/index';
import './style.css';
import Translation from '../translation';
import polygon from './assets/polygon.png';

const ActionButton = ({
  value,
  name,
  handleAction,
  style,
  isActive,
  type = 'bottom',
}: ActionButtonProps) => {
  // Attributes
  const [isHover, setIsHover] = useState(false);

  // Functions
  function renderIcon() {
    switch (name) {
      case ACTION_ICON.COMPANIES:
        return <Icons.CompanyIcon />;
      case ACTION_ICON.DISHES:
        return <Icons.DishIcon />;
      case ACTION_ICON.DETAILS:
        return <Icons.InfoIcon />;
      case ACTION_ICON.RESTAURANTS:
        return <Icons.RestaurantIcon />;
      case ACTION_ICON.EMPLOYEES:
        return <Icons.UserIcon />;
      case ACTION_ICON.STATISTICS:
        return <Icons.StatisticsIcon />;
      case ACTION_ICON.PROMO:
        return <Icons.PromoIcon />;
      case ACTION_ICON.ORDERS:
        return <Icons.OrdersIcon />;
      case ACTION_ICON.NEW_ORDERS:
        return <Icons.OrdersIcon />;
      case ACTION_ICON.NOTIFICATIONS:
        return <Icons.NotificationsIcon />;
      case ACTION_ICON.INDEX:
        return <Icons.PercentIcon />;
      case ACTION_ICON.CLIENT:
        return <Icons.ClientIcon />;
      case ACTION_ICON.ZONES:
        return <Icons.ZoneIcon />;
      case ACTION_ICON.APP_VERSIONING:
        return <Icons.AppVersioningIcon />;
    }
  }

  function renderClassName() {
    if (isActive) {
      return 'action-button active';
    }

    return 'action-button';
  }

  function renderPopOverClassName(className: string) {
    return `${className}-${type}`;
  }

  return (
    <div
      className='action-button-container'
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={renderClassName()}
        onClick={handleAction}
        style={{ ...style }}
      >
        {value ? (
          <div className='action-button-value-container'>
            <div className='action-button-value'>{value}</div>
            <div style={{ height: 'fill' }}>{renderIcon()}</div>
          </div>
        ) : (
          renderIcon()
        )}
      </div>
      {isHover && (
        <div className={renderPopOverClassName('table-action-pop-over')}>
          <div
            className={renderPopOverClassName('table-action-pop-over-polygon')}
          >
            <img
              className='table-action-pop-over-polygon-image'
              src={polygon}
              alt=''
            />
          </div>

          <div className={renderPopOverClassName('table-action-pop-over-body')}>
            <Translation>{name}</Translation>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionButton;
