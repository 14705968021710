/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type NotificationFrequency = typeof NotificationFrequency[keyof typeof NotificationFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFrequency = {
  UNIQUE_USE: 'UNIQUE_USE',
  EVERY_WEEK: 'EVERY_WEEK',
  EVERY_TWO_WEEKS: 'EVERY_TWO_WEEKS',
} as const;
