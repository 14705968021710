import Axios from 'axios';
import React from 'react'
import { RestaurantDTO, RestaurantType } from '../dtos/restaurantDTO'
import RestaurantMockService from './mocks/RestaurantMockService'
import Service from './Service';


async function get(restaurantId?: string) {
    if (restaurantId) {
        return Service.execute((Axios.get(`${Service.API_URL}/v1/api/restaurants/${restaurantId}`, Service.config())));
    }
    return Service.execute((Axios.get(`${Service.API_URL}/v1/api/restaurants`, Service.config())));
}

async function post(company: RestaurantDTO) {
    return Service.execute((Axios.post(`${Service.API_URL}/v1/api/restaurants`, company, Service.config())));
}

async function put(company: RestaurantDTO) {
    return Service.execute((Axios.put(`${Service.API_URL}/v1/api/restaurants`, company, Service.config())));
}

async function deleteRestaurant(restaurantId: string) {
    return Service.execute((Axios.delete(`${Service.API_URL}/v1/api/restaurants/${restaurantId}`, Service.config())));
}


let exported = { get, post, put, deleteRestaurant };

if(process.env.REACT_APP_MOCKED === "true")
{
    exported = RestaurantMockService;
}

export default exported;
