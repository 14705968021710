/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type FoodizPaymentMethod = typeof FoodizPaymentMethod[keyof typeof FoodizPaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FoodizPaymentMethod = {
  CREDITCARD: 'CREDITCARD',
  NEW_CARD: 'NEW_CARD',
  BANCONTACT: 'BANCONTACT',
  EDENRED: 'EDENRED',
  MONIZZE: 'MONIZZE',
  SODEXO: 'SODEXO',
  UNKNOWN: 'UNKNOWN',
} as const;
