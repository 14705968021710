/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PromoType = typeof PromoType[keyof typeof PromoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PromoType = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
  UNKNOWN: 'UNKNOWN',
} as const;
