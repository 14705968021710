import React, { createRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import { ReactComponent as PrinterIcon } from "../../assets/print-big.svg";
import PrintableOrderItemsByRestaurant from "./components/order-items";
import OrderTicketHeader from "./components/ticket-header";
import { PrintableOrderProps } from "./props";
import * as _ from "lodash";
import "./styles.css";
import OrderTicketFooter from "./components/ticket-footer";

const PrintableOrder = ({
  order,
  setIsModalOpen,
  setSelectedOrder,
}: PrintableOrderProps) => {
  //Attributes
  const { translate } = useTranslation();
  const componentRef = createRef<HTMLDivElement>();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef?.current,
    onAfterPrint: () => {
      setIsModalOpen(true);
      setSelectedOrder(order);
    },
  });

  // Goup order items by item restaurant name
  const itemsGroupByRestaurant = _.groupBy(order.items, "restaurantName");
  const nbrOfItemsInOrder = order?.items && order.items.length;

  return (
    <div
      style={{
        cursor: "pointer",
        backgroundColor: "#F5C755",
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
      }}
      onClick={handleReactPrint}
    >
      <PrinterIcon width=".875rem" height=".875rem" />
      <div
        style={{
          display: "none",
          // height: "100%",
          // start: theses style are for test purpose only
          // position: "absolute",
          // width: "100%",
          // height: "100%",
          // top: "0",
          // left: "20%",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          // end: theses style are for test purpose only
        }}
      >
        <div ref={componentRef} className="order-ticket-container">
          <div className="order-ticket-content">
            <div>
              {/* TICKET HEADER */}
              <OrderTicketHeader order={order} />

              {/* TICKET BODY */}
              <div className="order-ticket-body-container">
                <div className="order-ticket-body-title">
                  {translate("order-information")}
                </div>
                <div className="order-ticket-body-dishes">
                  {Object.keys(itemsGroupByRestaurant)?.map((name, i) => {
                    // to avoids overlapping on the footer of a restaurant section that has many items
                    // we cut the big array of restaurant items into little chunks of size 9
                    const restaurantItemsChunks = _.chunk(
                      itemsGroupByRestaurant[name],
                      9
                    );

                    return restaurantItemsChunks.map((chunk, index) => (
                      <div key={index}>
                        {i % 3 === 0 && i !== 0 && (
                          <div className="page-break" />
                        )}
                        <PrintableOrderItemsByRestaurant
                          restaurantName={name}
                          items={chunk}
                        />
                        {restaurantItemsChunks.length >= 2 && (
                          <div className="page-break" />
                        )}
                      </div>
                    ));
                  })}
                </div>

                {/* TICKET COMMENT IF ORDER HAS ANY */}
                {nbrOfItemsInOrder >= 9 && order?.comment ? (
                  <div className="order-comment-with-break-page">
                    <div className="page-break" />
                    <div className="order-ticket-footer-comment-container">
                      <div className="order-ticket-footer-comment-title">
                        {translate("order-comment")}
                      </div>
                      <div className="order-ticket-footer-comment-content">
                        {order?.comment}
                      </div>
                    </div>
                  </div>
                ) : (
                  order?.comment && (
                    <div className="order-ticket-footer-comment-container">
                      <div className="order-ticket-footer-comment-title">
                        {translate("order-comment")}
                      </div>
                      <div className="order-ticket-footer-comment-content">
                        {order?.comment}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <OrderTicketFooter order={order} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintableOrder;
