export default interface ActionButtonProps {
	name: string;
	backgroundColor?: any;
	handleAction: any;
	style?: any;
	isActive?: boolean;
	type?: "right" | "bottom";
	value?: any;
}

export enum ACTION_ICON {
	COMPANIES = "companies",
	INDEX = "index",
	DISHES = "dishes",
	DETAILS = "details",
	RESTAURANTS = "restaurants",
	USERS = "users",
	EMPLOYEES = "employees",
	STATISTICS = "statistics?type=day",
	PROMO = "promos",
	CLIENT = "client",
	ORDERS = "orders?type=day",
	NEW_ORDERS = "new-orders?type=day",
	NOTIFICATIONS = "notifications",
	ZONES = "zones",
	APP_VERSIONING = "app-versioning"
}
