import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PopupContextProvider from './components/popup-context/popupContextProvider';
import Sidebar from './components/sidebar';
import Companies from './modules/companies';
import Orders from './modules/orders';
import NewOrders from './modules/new-orders';
import Promo from './modules/promo';
import Restaurants from './modules/restaurants';
import Statistics from './modules/statistics';
import Notifications from './modules/notifications';
import AppVersioning from './modules/app-versioning';
import './style.css';
import Zones from './modules/zones';


const Dashboard = () => {
  return (
    <PopupContextProvider>
      <div className='dashboard-container'>
        <Sidebar />
        <Switch>
          <Route path='/dashboard/companies' component={Companies} />
          <Route path='/dashboard/restaurants' component={Restaurants} />
          <Route path='/dashboard/promos' component={Promo} />
          <Route path='/dashboard/statistics' component={Statistics} />
          <Route path='/dashboard/orders' component={Orders} />
          <Route path='/dashboard/new-orders' component={NewOrders} />
          <Route path='/dashboard/notifications' component={Notifications} />
          <Route path='/dashboard/zones' component={Zones} />
          <Route path='/dashboard/app-versioning' component={AppVersioning} />
          <Route
            path='/dashboard/'
            render={() => <Redirect to='/dashboard/companies' />}
          />
        </Switch>
      </div>
    </PopupContextProvider>
  );
};

export default Dashboard;
