import { ReactComponent as CompanyIcon } from './company.svg';
import { ReactComponent as DishIcon } from './dish.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as PercentIcon } from './percent.svg';
import { ReactComponent as UserIcon } from './user.svg';
import { ReactComponent as RestaurantIcon } from './restaurant.svg';
import { ReactComponent as StatisticsIcon } from './statistics.svg';
import { ReactComponent as PromoIcon } from './promo.svg';
import { ReactComponent as ClientIcon } from './client.svg';
import { ReactComponent as OrdersIcon } from './orders.svg';
import { ReactComponent as NotificationsIcon } from './notifications.svg';
import { ReactComponent as ZoneIcon } from './zone.svg';
import { ReactComponent as AppVersioningIcon } from "./app-versioning.svg";

export default {
  UserIcon,
  DishIcon,
  InfoIcon,
  PercentIcon,
  CompanyIcon,
  RestaurantIcon,
  StatisticsIcon,
  PromoIcon,
  ClientIcon,
  OrdersIcon,
  NotificationsIcon,
  ZoneIcon,
  AppVersioningIcon,
};
