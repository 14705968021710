import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Header from "../../../../../../components/header";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";
import OrderService from "../../../../../../services/OrderService";
import MenuContainer from "../../../../components/menu-container";
import PageContainer from "../../../../components/page-container";
import OrderClientInformationField from "./components/client-information-field";
import OrderCard from "./components/order-card";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ReactComponent as EmailIcon } from "./assets/email.svg";
import { ReactComponent as LocationIcon } from "./assets/location.svg";
import { ReactComponent as CompanyIcon } from "./assets/company.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { EmployeeDTO } from "../../../../../../dtos/employeeDTO";
import { formatLocationAddress } from "./utils";
import OrderTable from "./components/table";
import "./style.css";
import { utcToZonedTime, format } from "date-fns-tz";
import OrderLogService from "../../../../../../services/OrderLogService";
import { OrderLogDTO, OrderLogType } from "../../../../../../dtos/orderLogDTO";
import { useEffect } from "react";
import PrintableOrder from "../list/components/printable-order";

const OrdersDetail = (props: any) => {
	// Attributes
	const timeZone = "Europe/Berlin";
	const { translate } = useTranslation();
	const { id: orderId }: any = useParams();
	const goBackUrl = `/dashboard/new-orders` + useLocation().search;

	const query = useQuery(`order-${orderId}`, refreshOrder, {
		enabled: !!orderId,
	});
	const orderLogQuery = useQuery(`order-logs-${orderId}`, refreshOrderLog, {
		enabled: !!orderId,
	});
	const employee: EmployeeDTO | undefined = useMemo(() => {
		return query?.data?.basketDTO?.employeeDTO;
	}, [query?.data]);

	//Effects
	useEffect(() => {
		createOrderLogViewed();
	}, []);

	// Handlers
	async function refreshOrder() {
		return (await OrderService.getById(orderId)).data;
	}

	async function refreshOrderLog() {
		return (await OrderLogService.getByOrderId(orderId)).data;
	}

	async function createOrderLogViewed() {
		const orderLogDTO: OrderLogDTO = {
			orderId: orderId,
			orderLogType: 1,
			message: "",
		};
		await OrderLogService.createOrderLog(orderLogDTO);
	}

	// Render
	return (
		<BusyIndicator queries={[query, orderLogQuery]}>
			<PageContainer>
				<Header title={`${translate("order")} ${query?.data?.id}`} subTitle={<div className="order-detail-subtitle">{query?.data?.modificationDate && format(utcToZonedTime(new Date(query?.data?.modificationDate + "z"), timeZone), "HH:mm - dd/MM/yyyy")}</div>} hasBackButton={true} goBackUrl={goBackUrl} otherButtons={<PrintableOrder order={query.data} />} />
				<MenuContainer>
					<div className="order-detail-container">
						<div className="order-detail-container-left">
							<OrderCard title={`${translate("order-information")} (${query?.data?.id})`}>
								<OrderTable basketProducts={query?.data?.basketDTO?.basketProducts} />
							</OrderCard>
							<div className="order-detail-comment-container">
								<OrderCard title={translate("order-comment-title")}>
									<div className="order-detail-comment">{query.data && query.data.comment}</div>
								</OrderCard>
							</div>
						</div>
						<div className="order-detail-container-right">
							<div className="order-detail-container-right-top">
								<OrderCard title={translate("client-information")}>
									<div className="order-client-information-container">
										<div>
											<OrderClientInformationField title={translate("client-name")} value={employee?.user?.firstName && employee?.user?.lastName ? `${employee?.user.firstName} ${employee?.user.lastName}` : ""} icon={<UserIcon />} />
											<OrderClientInformationField
												title={translate("email-address")}
												value={employee?.user?.email}
												icon={<EmailIcon />}
												customStyle={{
													marginTop: "31px",
												}}
											/>
											<OrderClientInformationField
												title={translate("company-address")}
												value={query?.data?.addressDTO ? formatLocationAddress(query?.data?.addressDTO) : ""}
												icon={<LocationIcon />}
												customStyle={{
													marginTop: "31px",
												}}
											/>
										</div>
										<div style={{ marginLeft: "60px" }}>
											<OrderClientInformationField title={translate("company")} value={employee?.company?.name} icon={<CompanyIcon />} />

											<OrderClientInformationField
												title={translate("phone-number")}
												value={employee?.user?.phoneNumber}
												icon={<PhoneIcon />}
												customStyle={{
													marginTop: "31px",
												}}
											/>
										</div>
									</div>
								</OrderCard>
							</div>
						</div>
					</div>
				</MenuContainer>
			</PageContainer>
		</BusyIndicator>
	);
};

export default OrdersDetail;
