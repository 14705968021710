/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type NotificationStatus = typeof NotificationStatus[keyof typeof NotificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationStatus = {
  ENABLED: 'ENABLED',
  IN_PROGRESS: 'IN_PROGRESS',
  DISABLED: 'DISABLED',
} as const;
