/**
 * Generated by orval v6.11.0 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type ProductTagType = typeof ProductTagType[keyof typeof ProductTagType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductTagType = {
  VISIBLE: 'VISIBLE',
  INVISIBLE: 'INVISIBLE',
} as const;
