import Axios from "axios";
import FileDTO from "../dtos";
import Service from "./Service";

function post(file: FileDTO) {
    return Service.execute(Axios.post(`${Service.API_URL}/v1/api/files`, file, Service.config())).then(elt => elt.data);
}

export interface IUploadDocument {
    id: string,
    creationDate: string,
    modificationDate: string,
    file: any,
    fileName: string,
    fileExtension: string,
    fileURL: string
}

let exported = { post };

export default exported;