import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import AddButton from '../../../../../../components/add-button';
import BusyIndicator from '../../../../../../components/busy-indicator';
import Header from '../../../../../../components/header';
import SegmentTab from '../../../../../../components/segment-tab';
import { RestaurantDTO } from '../../../../../../dtos/restaurantDTO';
import useSearch from '../../../../../../hooks/use-search';
import useSegment from '../../../../../../hooks/use-segment';
import RestaurantService from '../../../../../../services/RestaurantService';
import CardGroup from '../../../../components/card-group';
import EmptyStates from '../../../../components/empty-states';
import MenuListContainer from '../../../../components/menu-list-container';
import PageContainer from '../../../../components/page-container';
import Search from '../../../../components/search';
import RestaurantCard from './components/restaurant-card';
import './style.css'

const RestaurantList = () => {

    // Attributes
    const history = useHistory();
    const query = useQuery('restaurants', refreshRestaurants)
    const { search, filtered, setSearch } = useSearch<RestaurantDTO>(query.data ? query.data : [], ["name"]);
    const { segmented, setSegment } = useSegment(filtered, handleSegment, "All")

    // Handlers
    async function refreshRestaurants() {
        return (await RestaurantService.get()).data;
    }


    function handleSegment(restaurant: RestaurantDTO, segment: any) {
        const { restaurantType } = restaurant;

        const segmentValue = mapSegmentWithValue(segment)

        switch (segmentValue) {
            case 0:
                return restaurantType === 0 || restaurantType === 2;
            case 1:
                return restaurantType === 1 || restaurantType === 2;
            case -1:
                return true
        }

        // return boolean

    }

    function mapSegmentWithValue(segment: string) {

        switch (segment) {
            case 'B2B':
                return 1;

            case 'B2C':
                return 0;

            case 'All':
                return -1
            default:
                break;
        }

    }

    function renderList() {
        if (search) {
            return filtered.map((restaurant) => <RestaurantCard restaurant={restaurant} key={`${restaurant.id}`} />)
        }
        return segmented.map((restaurant) => {
            return <RestaurantCard restaurant={restaurant} />
        })
    }


    return (
        <BusyIndicator query={query}>
            <PageContainer>
                <Header
                    title='Our restaurants'
                />
                {query.data && query.data.length > 0 ? (
                    <MenuListContainer>
                        <div className='dashboard-companies-actions-container'>
                            <div className='dashboard-restaurants-actions-first-part'>
                                <Search value={search} placeholder='Search for a restaurant' onChange={setSearch} />
                                <div style={{ marginRight: 31 }} />
                                <SegmentTab segments={["All", "B2B", "B2C"]} handleChange={(segment: string) => setSegment(segment)} />
                            </div>
                            <AddButton title='Add new restaurant' handleClick={() => history.push('/dashboard/restaurants/details')} />
                        </div>
                        <CardGroup>
                            {query?.data && renderList()}
                        </CardGroup>
                    </MenuListContainer>
                )
                    :
                    (
                        <EmptyStates
                            menu='restaurants'
                        />
                    )}
            </PageContainer>
        </BusyIndicator>
    )
}

export default RestaurantList;